<template>
	<div>
		<div class="taskItem taskItem0" v-for="taskItem in taskCatalogList" :key="taskItem.id">
			<!-- 培训任务没有目录 -->
			<template v-if="!hasTaskCatalog(taskItem)">
          <div class="taskContentItemWrapper taskContentItemWrappe0">
              <TaskContentItem :taskDetail="taskDetail" :taskItem="taskItem"></TaskContentItem>
          </div>
			</template>

			<!-- 一级目录 -->
			<template v-else>
				<el-collapse class="taskCollapse" :class="{ locked: taskItem.isLocked }" v-model="taskActiveNamea" accordion>
          <!-- 上锁状态并且不是线下实训 不让展开 -->
					<el-collapse-item :name="taskItem.id" :class="{OfflineTrainBox:taskItem.type === 8}" :disabled="taskItem.isLocked && taskItem.type !== 8">
						<template #title>
							<div class="taskTitle" @click="handleClickCatalog(taskItem)">
                <div class="title">
                  <span class="label"  v-if="taskItem.type === 8">{{ConstData.taskType[taskItem.type].text}}</span>
                  <span class="stageName">{{ taskItem.stageName || taskItem.contentName }}</span>
                </div>
								<svg-icon v-if="taskItem.isLocked" icon-class="lock"></svg-icon>
							</div>
						</template>
						<div class="taskItem taskItem1" v-for="item1 in taskItem.childItemList" :key="item1.id">
							<template v-if="!hasTaskCatalog(item1)">
								<TaskContentItem
									:taskDetail="taskDetail"
									:taskItem="item1"
                  :type="taskItem.type"
									@getLastStudyId="getLastStudyId"
									:className="'innerTaskCatalog'"
								></TaskContentItem>
							</template>

							<!-- 二级目录 -->
							<template v-else>
								<el-collapse class="taskCollapse taskCollapse2" v-model="taskActiveNameaa" accordion>
									<el-collapse-item :name="item1.id" :disabled="item1.isLocked">
										<template #title>
											<div class="taskTitle taskTitle2" @click="handleClickCatalog(item1)">
												<span>{{ item1.stageName }}</span>
												<svg-icon v-if="item1.isLocked" icon-class="lock"></svg-icon>
											</div>
										</template>

										<div class="taskItem taskItem2" v-for="item2 in item1.childItemList" :key="item2.id">
											<TaskContentItem
												:taskDetail="taskDetail"
												:taskItem="item2"
												@getLastStudyId="getLastStudyId"
												:className="'innerTaskCatalog'"
											></TaskContentItem>
										</div>
									</el-collapse-item>
								</el-collapse>
							</template>
						</div>
					</el-collapse-item>
				</el-collapse>
			</template>
		</div>
	</div>
</template>

<script>
import TaskContentItem from './taskContentItem'
export default {
	name: 'taskItem',
	data() {
		return {
			taskActiveNamea: '',
			taskActiveNameaa: '',
		}
	},
	props: {
		taskDetail: Object,
		taskCatalogList: Array,
	},
	watch: {
		taskDetail(val) {
			if (val.id) {
				this.quickLearn(val.id)
			}
		},
	},
	components: {
		TaskContentItem,
	},
	mounted() {},
	methods: {
		quickLearn(id) {
			const params = {
				taskId: id,
				taskType: 2,
			}
			return this.$api.course.quickLearn({ params }).then((res) => {
				if (!res.data) {
					// console.log(res.data, 'quicklearn__________________')

					//处理没有课程时默认展开
					let catalog1 = this.taskCatalogList[0]
					let catalog2 = catalog1 && catalog1.childItemList[0]
					if (catalog1 && catalog1.type === 1) {
						this.taskActiveNamea = catalog1.id
					}
					if (catalog2 && catalog2.type === 1) {
						this.taskActiveNameaa = catalog2.id
					}
				}
			})
		},
		// 点击章节上锁提示
		handleClickCatalog(taskItem) {
			if (taskItem.isLocked && taskItem.type !== 8) {
				if (localStorage.getItem('theme') == 'junmin') {
					this.$message({
						message: this.multiPopTips(),
						type: 'warning',
					})
				} else {
					this.$message.info(this.multiPopTips())
				}
			}
		},
		multiPopTips(){
			if(this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition===1 && this.taskDetail.isFormCommit){
                return this.$t('WDNPC_WEB_IF_THIS_CHAPTER_IS_NOT_ENABLED_THE',{text:'该章未开启，开启条件为前面的学习内容学习进度达到'}) + `${this.taskDetail.lockCourseRate}%`+this.$t('WDNPC_WEB_SUBMIT_THE_EXAM_PASS_FORM',{text:'，考试及格，表单提交'});
            }else if(this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition===0 && this.taskDetail.isFormCommit){
                return this.$t('WDNPC_WEB_IF_THIS_CHAPTER_IS_NOT_ENABLED_THE',{text:'该章未开启，开启条件为前面的学习内容学习进度达到'}) + `${this.taskDetail.lockCourseRate}%`+this.$t('WDNPC_WEB_FORM_SUBMISSION',{text:'，表单提交'});

            }else if(!this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition===0 && this.taskDetail.isFormCommit){
                return this.$t('WDNPC_WEB_IF_THIS_CHAPTER_IS_NOT_ENABLED_THE_PYX',{text:'该章未开启，开启条件为表单提交'});
            } else if(!this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition===1 && !this.taskDetail.isFormCommit ){
                return this.$t('WDNPC_WEB_IF_THIS_CHAPTER_IS_NOT_OPENED_THE',{text:'该章未开启，开启条件为考试及格'});
            } else if(this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition===0 && !this.taskDetail.isFormCommit){
                return this.$t('WDNPC_WEB_IF_THIS_CHAPTER_IS_NOT_ENABLED_THE',{text:'该章未开启，开启条件为前面的学习内容学习进度达到'}) + `${this.taskDetail.lockCourseRate}%`;
            } else if(this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition && !this.taskDetail.isFormCommit) {
                return this.$t('WDNPC_WEB_IF_THIS_CHAPTER_IS_NOT_ENABLED_THE',{text:'该章未开启，开启条件为前面的学习内容学习进度达到'})+`${this.taskDetail.lockCourseRate}%`+this.$t('WDNPC_WEB_PASS_THE_EXAM',{text:'，考试及格'});

            } else if(!this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition && this.taskDetail.isFormCommit) {
                return this.$t('WDNPC_WEB_IF_THIS_CHAPTER_IS_NOT_ENABLED_THE_YYS',{text:'该章未开启，开启条件为考试及格，表单提交'});
            }
		},
		defaultExpand(id) {
			var parentNode = null
			var node = null

			function getNode(json, target) {
				for (var i = 0; i < json.length; i++) {
					if (node) {
						break
					}
					var obj = json[i]
					if (!obj || !obj.id) {
						continue
					}

					if (obj.id == target) {
						node = obj
						if (obj.parentId) {
							parentNode = { id: obj.parentId }
						}
						break
					} else {
						if (obj.childItemList && obj.childItemList.length != 0) {
							getNode(obj.childItemList, target)
						} else {
							continue
						}
					}
				}
				if (!node) {
					parentNode = null
				}
				return {
					parentNode: parentNode,
					node: node,
				}
			}

			var result = []
			var getResult = function(json, query) {
				node = null
				parentNode = null
				var obj = getNode(json, query)
				if (obj.parentNode) {
					getResult(json, obj.parentNode.id)
					result.push(obj.parentNode.id)
				}
				return result
			}
			// getResult(this.catalogList, this.lastStudyId)
			getResult(this.taskCatalogList, id)
			console.log(result, 'result------------------------')

			if (result.length) {
				let str = 'taskActiveName'
				for (var i = 0; i < result.length; i++) {
					str += 'a'
					this[str] = result[i]
				}
			}
		},
		// 默认展开
		getLastStudyId(lastId) {
			this.defaultExpand(lastId)
			// this.taskActiveName = lastId
		},
		// 有无培训目录
		hasTaskCatalog(taskItem) {
			// if (taskItem.childItemList && taskItem.childItemList.length) {
			// 	return true
			// } else {
			// 	return false
			// }
      //  如果是目录和线下实训 展示下拉
			if (taskItem &&( taskItem.type === 1 || taskItem.type === 8) && taskItem.childItemList && taskItem.childItemList.length) {
				return true
			} else {
				return false
			}
		},
	},
}
</script>

<style lang="stylus" scoped>
@import '../css/taskItem.styl'
</style>
