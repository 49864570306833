<template>
	<!-- <div class="taskContentItemWrapper"> -->
	<div class="taskContentItem">
		<!-- 1 目录 2 课程  3 考试 4  直播 5 实训  6 表单  7练习  8 线下实训-->
		<div :class="['task', className, { locked: taskItem.isLocked }]" v-if="taskItem.type !== 2" @click="toDetail(taskItem)">
			<div class="item_left" v-if="type !== 8">
				<div class="item_left_top">
					<span class="tag" v-if="taskItem.type" :class="{ isLock }">
            {{ConstData.taskType[taskItem.type].text}}   
					</span>
					<span class="name">{{ taskItem.stageName || taskItem.contentName }}</span>
					<span v-if="taskItem.dateExpireStatus == 2">{{$t('WDNPC_WEB_EXPIRED_AND_CANNOT_BE_VIEWED',{text:'已过期不可查看'})}}</span>
				</div>

				<!-- 考试 -->
				<template v-if="taskItem.type === 3">
					<div class="item_left_bottom">
						<span class="time" v-if="taskItem.isLimitTime==1">
							考试时间：{{ taskItem.contentStartTime && taskItem.contentStartTime.replace(/-/g, '.') }} -
							{{ taskItem.contentEndTime && taskItem.contentEndTime.replace(/-/g, '.') }}
						</span>
						<span class="time" v-else-if="taskItem.isLimitTime==2">
							考试时间：每月定期（{{taskItem.regularDate}}）
						</span>
						<span class="time" v-else>
							考试时间：长期有效
						</span>
					</div>
				</template>
				<!-- 直播 -->
				<template v-else-if="taskItem.type === 4">
					<div class="item_left_bottom">
						<span class="time">
							{{$t('WDNPC_WEB_LIVE_BROADCAST_TIME',{text:'直播时间：'})}}{{ taskItem.contentStartTime && taskItem.contentStartTime.replace(/-/g, '.') }} -
							{{ taskItem.contentEndTime && taskItem.contentEndTime.replace(/-/g, '.') }}
						</span>
					</div>
				</template>
				<!-- 表单 -->
				<template v-else-if="taskItem.type === 6 && taskItem.contentEndTime">
					<div class="item_left_bottom">
						<span class="time"> {{$t('WDNPC_WEB_END_TIME',{text:'结束时间：'})}}{{ taskItem.contentEndTime && taskItem.contentEndTime.replace(/-/g, '.') }} </span>
					</div>
				</template>
				<!-- 线下实训 -->
				<!-- <template v-else-if="taskItem.type === 1 && taskItem.isTraining">
					<div class="item_left_bottom">
						<span class="isTraining" v-if="taskItem.trainingResult === 0">
							$t('WDNPC_WEB_UNQUALIFIED_GRADES',{text:'成绩不合格'})
						</span>
						<span class="isTraining" v-if="taskItem.trainingResult === 1">
							$t('WDNPC_WEB_QUALIFIED_RESULTS',{text:'成绩合格'})
						</span>
						<span class="isTraining" v-if="taskItem.trainingResult === 2">
							$t('WDNPC_WEB_RESERVED',{text:'已预约'})
						</span>
						<span class="isTraining" v-else>
							$t('WDNPC_WEB_NO_RESERVATION',{text:'未预约'})
						</span>
					</div>
				</template> -->
			</div>
			<div class="item_right" v-if="type !== 8">
        <div class="examButton" v-if="taskItem.type === 3">进入考试</div>
				<svg-icon v-if="taskItem.isLocked" icon-class="lock"></svg-icon>
				<!-- 实训 -->
				<!-- <template v-else-if="taskItem.type === 1 && taskItem.trainingResult != 1">
					<span class="apply" v-if="taskItem.isReserved && taskItem.trainingResult != 0">查看预约</span>
					<span class="apply" v-else>$t('WDNPC_WEB_APPOINTMENT',{text:'预约'})</span>
				</template> -->
				<!-- 表单 -->
				<template v-else-if="taskItem.type === 6">
					<span>{{ taskItem.submitStatus ? $t('WDNPC_WEB_FILLED_IN',{text:'已填写'}) : $t('WDNPC_WEB_NOT_FILLED_IN',{text:'未填写'}) }}</span>

				</template>
				<!-- 直播 -->
				<template v-else-if="taskItem.type === 4 && taskItem.isLiveReplay && taskItem.status === 2 && !taskItem.isLocked">
					<span class="watch" @click="jumpReplay(taskItem, $event)">{{$t('WDNPC_WEB_WATCH_PLAYBACK',{text:'观看回放'})}}</span>
				</template>
			</div>
      <!-- 线下实训内容 -->
      <div v-if="type === 8" class="OfflineTrainContent">
          <div class="OfflineTrain-left" :class="{OfflineTraninJeishu:(getNowFormatTime() > (taskItem.endTime && (taskItem.endTime.split(' ')[0]+' '+taskItem.endTime.split(' ')[2]) )),OfflineTraninweikaishi:(getNowFormatTime() < (taskItem.startTime && (taskItem.startTime.split(' ')[0]+' '+taskItem.startTime.split(' ')[2]) ))}">
              <p class="circle"></p>
              <p class="line"></p>
          </div>
          <div class="OfflineTrain-right" @click="offlineTrainButton(taskItem)">
              <div class="date">{{taskItem.startTime}}——{{taskItem.endTime}}</div>
              <div class="train-bottom">
                  <div class="train-content-left" :class="{delBottomRadius:taskItem.signInTime&&taskItem.signBackTime}">
                      <h2 class="name">{{taskItem.courseName || taskItem.examName}}</h2>
                      <p class="add p1"><span>{{$t('WDNPC_WEB_LOCATION',{text:'地点：'})}}</span>{{taskItem.address}}</p>
                      <p class="qiandaoTime p1" v-if="taskItem.signInTime || getNowFormatTime() > taskItem.signInEndTime"><span>{{$t('WDNPC_WEB_SIGNIN_TIME',{text:'签到时间：'})}}</span>{{taskItem.signInTime || '--'}}</p>
                      <p class="qiantuioTime p1" v-if="taskItem.signBackTime || getNowFormatTime() > taskItem.signBackEndTime"><span>{{$t('WDNPC_WEB_SIGN_BACK_TIME',{text:'签退时间：'})}}</span>{{taskItem.signBackTime || '--'}}</p>
                      <!-- <p class="qiandaoTime p1" v-if="taskItem.signInTime"><span>签到时间：</span>{{taskItem.signInTime}}</p>
                      <p class="qiantuioTime p1" v-if="taskItem.signBackTime"><span>$t('WDNPC_WEB_SIGN_BACK_TIME',{text:'签退时间：'})</span>{{taskItem.signBackTime}}</p> -->
                  </div>
                <div class="train-button-right">
                    <p v-if="taskItem.trainingType === 2" :style="taskItem.examButton === 3 || taskItem.examButton === 0 ? 'color:#999' : 'color:#316FFF' ">{{taskItem.examButton === 1 || taskItem.examButton === 0 ? $t('WDNPC_WEB_START_EXAM',{text:'开始考试'}) : taskItem.examButton === 2 ? $t('WDNPC_WEB_ENDED',{text:'已结束'}):$t('WDNPC_WEB_VIEW_RESULTS',{text:'查看成绩'})}}</p>


                </div>
              </div>
              
          </div>
      </div>
			<!-- <div class="hoverTip" v-if="!taskItem.isLocked">
                    $t('WDNPC_WEB_VIEW_DETAILS',{text:'查看详情'})
                </div> -->
		</div>
		<!-- 课程 -->
		<CourseDetail v-else :courseDetail="taskItem" @getLastStudyId="getLastStudyId" :className="className"></CourseDetail>
	</div>
	<!-- </div> -->
</template>

<script>
import CourseDetail from './courseDetail'
import {goLiveHome} from "../../live/common/livePlatform";
export default {
	name: 'taskContentItem',
	data() {
		return {
			isLock: true,
			isCourse: true,
		}
	},
	props: {
		taskDetail: Object,
		taskItem: Object,
		className: String,
    type:Number
	},
	components: {
		CourseDetail,
	},
	mounted() {
		// console.log('taskItem--------', this.taskItem)
	},
	methods: {
    getNowFormatDay(nowDate) {
          var char = "-";
        if(nowDate == null){
            nowDate = new Date();
        }
        var day = nowDate.getDate();
        var month = nowDate.getMonth() + 1;//注意月份需要+1
        var year = nowDate.getFullYear();
        //补全0，并拼接
        return year + char + this.completeDate(month) + char +this.completeDate(day);
    },
    getNowFormatTime() {
        var nowDate = new Date();
        var colon = ":";
        var h = nowDate.getHours();
        var m = nowDate.getMinutes();
        var s = nowDate.getSeconds();
        //补全0，并拼接
        return this.getNowFormatDay(nowDate) + " " + this.completeDate(h) + colon + this.completeDate(m) + colon + this.completeDate(s);
    },

    //补全0
    completeDate(value) {
        return value < 10 ? "0"+value:value;
    },
    // 线下实训按钮点击
    offlineTrainButton(item){
        console.log(item,'item====')
        if(item.examButton === 0){
            this.$message.warning(this.$t('WDNPC_WEB_EXAM_TIME_NOT_REACHED',{text:'未到考试时间'}))
        }else if(item.examButton === 1){
            // 判断是否抽题 跳转不同路由
            if(item.questionId){
                this.$router.push({
                  path:'/offlineStem',
                  query:{
                      id:item.examId,
                      questionId:item.questionId,
                      taskQuery:JSON.stringify(this.$route.query)
                  }
                })
            }else {
                this.$router.push({
                  path:'/offlineTraining',
                  query:{
                      id:item.examId,
                      taskQuery:JSON.stringify(this.$route.query)
                  }
                })
            }
        }else if(item.examButton === 2){
            this.$message.warning(this.$t('WDNPC_WEB_THE_EXAM_HAS_ENDED',{text:'考试已结束'}))
        }else if(item.examButton === 3){
            this.$router.push({
              path:'/offlineTraining/preview',
              query:{
                  id:item.examUserId,
                  taskQuery:JSON.stringify(this.$route.query)
              }
            })
        }
    },
		getLastStudyId(lastId) {
			this.$emit('getLastStudyId', lastId)
		},
		// 观看直播回放
		jumpReplay(item, e) {
			e.stopPropagation()
            goLiveHome(this,item,item.contentId,localStorage.getItem('current_language'),localStorage.getItem('timezone'))
			// this.$api.live.getBjyPlayUrl({ liveCourseId: item.contentId }).then(async (resB) => {
			// 	if (resB.data.roomStatus == 1) {
			// 		// 腾讯
			// 		if (item.isReplay) {
			// 			this.$api.live.saveViewUser(item.contentId).then((res) => {
			// 				if (res.success) {
			// 					this.$router.push({
			// 						path: '/livePlayback',
			// 						query: {
			// 							roomId: item.contentId,
			// 							sourceId: item.replayResourceId,
			// 						},
			// 					})
			// 				}
			// 			})
			// 		} else {
			// 			this.$message.warning('视频处理中，请次日查看~')
			// 		}
			// 	} else if (resB.data.roomStatus == 2 || resB.data.roomStatus == 4) {
			// 		// 百家云 cc
			// 		this.$api.live.saveViewUser(item.contentId).then((res) => {
			// 			if (res.success) {
			// 				this.$router.push({
			// 					path: '/bjyLivePlayBack',
			// 					query: {
			// 						contentId: item.contentId,
			// 					},
			// 				})
			// 			}
			// 		})
			// 	}
			// })
		},
		toDetail(item) {
			if (item.type === 1 && item.isTraining) {
				// 去实训列表
				if (!item.isTrainingCanReserve) {
					if (item.trainingResult !== 1) {
						// 成绩不合格
						this.$toast({
							message: this.$t('WDNPC_WEB_YOU_CAN_MAKE_AN_APPOINTMENT_AFTER_THE',{text:'正式考核通过后可预约'}),
							position: 'middle',
						})
						return
					} else {
						return
					}
				}
				this.$router.push({
					path: '/learning/subscribeList',
					query: {
						taskId: item.taskId,
						searchKey: '',
					},
				})
				return
			} else if (item.type === 1 && item.isLocked) {
				this.$message.warning({ message: this.multiPopTips(), offset: 70 })
			} else if (item.type === 2 && item.isLocked) {
				this.$message.warning({ message: this.multiPopTips(), offset: 70 })
			} else if (item.type === 3) {
				if (item.isLocked) {
					this.$message.warning({ message: this.multiPopTips(), offset: 70 })
					return false
				} else {
					// 加startTime，endTime两个参数主要是为了国际化翻译切换语言的时候使用
					// this.taskDetail.cycleType === 1
					// ? this.taskDetail.startTime.replace(/-/g, '.') + '至' + this.taskDetail.endTime.replace(/-/g, '.')
					// : this.taskDetail.cycleType === 2
					// ? this.taskDetail.endTime.split(' ')[0].replace(/-/g, '.')
					// : this.$t('WDNPC_WEB_LONG_TERM_EFFECTIVE',{text:'长期有效'}),
					if(this.taskDetail.cycleType === 1){
                        this.startTime = this.taskDetail.startTime
                        this.endTime = this.taskDetail.endTime
                    }else if(this.taskDetail.cycleType === 2){
                        this.startTime = ''
                        this.endTime = this.taskDetail.endTime
                    }else{
                        this.timeSlot = 0
                    }
					this.$router.push({
						path: '/examDetails',
						query: {
							examId: item.contentId, //考试ID
							bussinessId: item.taskId, //任务ID
							type: 2, //训练营1，任务2
							title: this.taskDetail.name,
							pathName: this.$route.path,
							backPath: this.$route.query.backPath,
							timeSlot:this.timeSlot,
							startTime: this.startTime,
							endTime:this.endTime
						},
					})
				}
			} else if (item.type === 4) {
				if (item.isLocked) {
					this.$message.warning({ message: this.multiPopTips(), offset: 70 })
					return false
				} else {
                    goLiveHome(this, item, item.contentId,localStorage.getItem('current_language'),localStorage.getItem('timezone'))
                    // this.$api.live.getBjyPlayUrl({ liveCourseId: item.contentId }).then(async (resB) => {
					// 	console.log('hanmeng==== resB', resB)
					// 	if (resB.data.roomStatus == 1) {
					// 		// 腾讯
					// 		this.$api.live.audienceEnterRoom({ id: item.contentId }).then(async (res) => {
					// 			if (res.success) {
					// 				if (res.success) {
					// 					window.open(window.location.origin + `/studentSee?type=1&roomId=${item.contentId}`)
					// 				}
					// 			}
					// 		})
					// 	} else if (resB.data.roomStatus == 2 || resB.data.roomStatus == 5) {
					// 		// 百家云
					// 		window.open(window.location.origin + `/bjyStudent?&contentId=${item.contentId}`)
					// 	} else if (resB.data.roomStatus == 3) {
					// 		// 百家云
					// 		if (item.status == 2) {
					// 			this.$message.warning('直播已结束')
					// 			return
					// 		}
					// 		window.open(window.location.origin + `/zegoroomkit?&roomId=${item.contentId}`)
					// 	} else if (resB.data.roomStatus == 4) {
					// 		// 百家云自定义
					// 		if (item.status == 2) {
					// 			this.$message.warning('直播已结束')
					// 			return
					// 		}
					// 		this.$api.live.audienceEnterRoom({ id: item.contentId }).then(async (res) => {
					// 			if (res.success) {
					// 				if (res.success) {
					// 					window.open(window.location.origin + `/bjyCustomStudent?&roomId=${resB.data.room_id}`)
					// 				}
					// 			}
					// 		})
					// 	} else if (resB.data.roomStatus == 6) {
					// 		window.open(window.location.origin + `/bjyStudent?&contentId=${item.contentId}`)
					// 	}
					// })
				}
			} else if (item.type === 5) {
				if (item.isLocked) {
					//this.$message.info(`该实训未开启，开启条件为前面的学习内容学习进度达到 ${this.taskDetail.lockCourseRate}%${this.taskDetail.lockExamCondition?'，考试及格。':'。'}`)
					this.$message.warning({ message: this.multiPopTips(), offset: 70 })
				} else {
					this.getdrillDetail(item.contentId)
				}
			} else if (item.type === 6) {
				if (item.isLocked) {
					this.$message.warning({ message: this.multiPopTips(), offset: 70 })
				} else {
					this.$router.push({
						path: '/learning/formPage',
						query: {
							type: this.$route.query.backMoudle == 'trainingTask' ? 2 : this.$route.query.backMoudle == 'trainingCamp' ? 1 : 2, // type：1训练营 2培训任务
							typeId: this.$route.query.id || this.$route.query.taskId, // typeId：训练营id或者培训任务id
							wjId: item.contentId, // wjId：问卷ID
						},
					})
				}
			} else if (item.type === 7) {
				if (item.isLocked) {
					//this.$message.info(`该课程未开启，开启条件为前面的学习内容学习进度达到 ${this.taskDetail.lockCourseRate}%${this.taskDetail.lockExamCondition?'，考试及格。':'。'}`)
					this.$message.warning({ message: this.multiPopTips(), offset: 70 })
					return false
				} else {
					this.$router.push({
						path: '/pattern',
						query: {
							businessId: item.taskId,
							practiceId: item.contentId,
							businessType: 2,
						},
					})
				}
			}
		},
    multiPopTips(){
			if(this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition===1 && this.taskDetail.isFormCommit){
                return this.$t('WDNPC_WEB_IF_THIS_CHAPTER_IS_NOT_ENABLED_THE',{text:'该章未开启，开启条件为前面的学习内容学习进度达到'}) + `${this.taskDetail.lockCourseRate}%`+this.$t('WDNPC_WEB_SUBMIT_THE_EXAM_PASS_FORM',{text:'，考试及格，表单提交'});
            }else if(this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition===0 && this.taskDetail.isFormCommit){
                return this.$t('WDNPC_WEB_IF_THIS_CHAPTER_IS_NOT_ENABLED_THE',{text:'该章未开启，开启条件为前面的学习内容学习进度达到'}) + `${this.taskDetail.lockCourseRate}%`+this.$t('WDNPC_WEB_FORM_SUBMISSION',{text:'，表单提交'});

            }else if(!this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition===0 && this.taskDetail.isFormCommit){
                return this.$t('WDNPC_WEB_IF_THIS_CHAPTER_IS_NOT_ENABLED_THE_PYX',{text:'该章未开启，开启条件为表单提交'});
            } else if(!this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition===1 && !this.taskDetail.isFormCommit ){
                return this.$t('WDNPC_WEB_IF_THIS_CHAPTER_IS_NOT_OPENED_THE',{text:'该章未开启，开启条件为考试及格'});
            } else if(this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition===0 && !this.taskDetail.isFormCommit){
                return this.$t('WDNPC_WEB_IF_THIS_CHAPTER_IS_NOT_ENABLED_THE',{text:'该章未开启，开启条件为前面的学习内容学习进度达到'}) + `${this.taskDetail.lockCourseRate}%`;
            } else if(this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition && !this.taskDetail.isFormCommit) {
                return this.$t('WDNPC_WEB_IF_THIS_CHAPTER_IS_NOT_ENABLED_THE',{text:'该章未开启，开启条件为前面的学习内容学习进度达到'})+`${this.taskDetail.lockCourseRate}%`+this.$t('WDNPC_WEB_PASS_THE_EXAM',{text:'，考试及格'});

            } else if(!this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition && this.taskDetail.isFormCommit) {
                return this.$t('WDNPC_WEB_IF_THIS_CHAPTER_IS_NOT_ENABLED_THE_YYS',{text:'该章未开启，开启条件为考试及格，表单提交'});
            }
		},
		// 获取实训详情
		getdrillDetail(id) {
			this.$api.learning.getdrillDetail({ id }).then((res) => {
				if (res.data) {
					window.open(window.location.origin + `/training_detail?courseId=${res.data.courseId}&labId=${res.data.labId}`)
				}
			})
		},
	},
}
</script>

<style lang="stylus" scoped src="../css/taskContentItem.styl"></style>
