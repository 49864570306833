<template>
	<div class="taskDetail">
		<div class="taskDetail_inner">
			<div class="backButton">
				<span @click="goBack">
					<i class="back el-icon-arrow-left"></i>
					{{$t('WDNPC_WEB_RETURN',{text:'返回'})}}
				</span>
			</div>
			<div class="content_header">
				<div class="left">
					<img :src="taskDetail.coverUrl" class="coverUrl" alt="" />
				</div>
				<div class="right">
					<div class="right_up">
						<div class="name">
							{{ taskDetail.name }}
						</div>
            <div class="label">
                <p class="label1" v-if="taskDetail.vocation">
                    {{$t('WDNPC_WEB_OCCUPATION',{text:'所属职业：'})}}<span>{{taskDetail.vocation.vocationName}}</span>
                </p>
                <p class="label2" v-if="taskDetail.workType">
                    {{$t('WDNPC_WEB_TYPE_OF_WORK',{text:'所属工种：'})}}<span>{{taskDetail.workType.vocationName}}</span>
                </p>
                <p class="label3" v-if="taskDetail.skillLevel">
                    {{$t('WDNPC_WEB_SKILL_LEVEL',{text:'技能等级：'})}}<span>{{taskDetail.skillLevel.vocationName}}</span>
                  </p>
            </div>
						<ul class="tag">
							<li v-for="item in taskDetail.taskItemTypeList" :key="item.type">
								<span>{{tag[item.type]}}：</span>
								<span>{{ item.count }}</span>
							</li>
						</ul>
					</div>
					<div class="right_down" v-if="is_taskTime">
						<div class="expired" v-show="$route.query.validity === '' || $route.query.validity === 'true'">
							<span class="expiredKey">{{$t('WDNPC_WEB_TASK_CYCLE',{text:'任务周期：'})}}</span>
							<span class="expiredVal" v-if="taskDetail.cycleType === 1"
								>{{ taskDetail.startTime.split(' ')[0].replace(/-/g, '.') }} 至 {{ taskDetail.endTime.split(' ')[0].replace(/-/g, '.') }}</span
							>
							<span class="expiredVal" v-if="taskDetail.cycleType === 2"
								>{{$t('WDNPC_WEB_VALID',{text:'有效期至：'})}}{{ taskDetail.endTime.split(' ')[0].replace(/-/g, '.') }}</span
							>
							<span class="expiredVal" v-if="taskDetail.cycleType === 3">{{$t('WDNPC_WEB_LONG_TERM_EFFECTIVE',{text:'长期有效'})}}</span>
						</div>
					</div>
				</div>
				<div class="status">
					<!-- <svg-icon v-if="!taskDetail.studyProcess" icon-class="jinxingzhong"></svg-icon>
					<svg-icon v-else-if="taskDetail.studyProcess === 100" icon-class="jinxingzhong"></svg-icon>
					<svg-icon v-else icon-class="jinxingzhong"></svg-icon> -->
					<span class="status-tag">{{$t('WDNPC_WEB_IN_PROGRESS',{text:'进行中'})}}</span>
				</div>
			</div>
			<div class="content_body">
				<div class="tabs">
					<span :class="{ isActive: !isCatalog }" @click="isCatalog = false">{{$t('WDNPC_WEB_INTRO',{text:'简介'})}}</span>
					<span :class="{ isActive: isCatalog }" @click="isCatalog = true">{{$t('WDNPC_WEB_DIRECTORY',{text:'目录'})}}</span>
				</div>
				<div class="inner">
					<!-- 培训任务根节点直接挂载  课程、考试、直播、实训、表单 -->
					<div class="list" v-if="isCatalog">
						<!-- 有直播任务时提示 -->
						<div class="zb-tip" v-if="isHaveLive">
							{{$t('WDNPC_WEB_WE_RECOMMEND_THAT_YOU_USE_A_BROWSER',{text:'建议使用Chrome浏览器/360极速浏览器。'})}}
						</div>

						<TaskItem :taskDetail="taskDetail" :taskCatalogList="taskDetail.taskItemVoList"></TaskItem>
					</div>
					<div class="taskDesc" v-else v-html="taskDetail.description"></div>
				</div>
			</div>
		</div>
    <el-dialog
      v-if="taskTipDialogVisible"
      title=""
      :visible.sync="taskTipDialogVisible"
      width="600px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :before-close="taskTiphandleClose">
      <div class="taskTipContent">{{taskRuleInfo.syllabusHintContent}}</div>
      <div class="taskTipFooter">
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="taskTiphandleClose">知道了</el-button>
        </span>
      </div>
    </el-dialog>
		<facedialog
      v-if="verification"
      :confirmdialog="confirmdialog"
      :type="1"
      :is_course_image_export="true"
      :pauseFrame="true"
      :taskId="taskDetail.id"
    ></facedialog>
	</div>
</template>
<script>
import TaskItem from './taskItem'
export default {
	name: 'taskDetail',
	data() {
		return {
			confirmdialog:{
        show:true,
      },
			verification: false,
			taskDetail: {}, //任务详情
      taskTipDialogVisible:false,
      taskTipContent:'内容内容内容内容',
			tag: {
				2: '课程',//{text:'课程'})',
				3: '考试',//{text:'考试'})',
				4: '直播',//{text:'直播'})',
				5: '线上实训',//{text:'线上实训'})',
				6: '表单',//{text:'表单'})',
				7: '练习',//{text:'练习'})',
				8: '线下实训',//{text:'线下实训'})',
			}, //内容类型
			isCatalog: true,
			isLock: true,
			taskActiveName: '', //培训任务目录
			isFaceRecognize: null, // 是否需要人脸识别
			isHaveLive: false, //是否有直播任务
      is_taskTime:true, // 是否显示培训任务周期
      taskRuleInfo:null,
			Task_interface_optimize:false,
			task_show_facedialog_rule:{ //人脸识别规则
				range:'course',
				isOnce:false
			}
		}
	},
	components: {
		TaskItem,
	},
	async created() {
    await this.findTaskRule();
    // 首次进入弹出提示
    if(this.taskRuleInfo.openSyllabusHintSwitch && this.taskRuleInfo.hintCycle === 1 && this.$route.query.isNewTask == 'true'){
        this.taskTipDialogVisible = true;
    }
    // 每次进入弹出提示
    if(this.taskRuleInfo.openSyllabusHintSwitch && this.taskRuleInfo.hintCycle === 2 && sessionStorage.getItem('ishint')){
        this.taskTipDialogVisible = true;
    }
    const configurationArr = localStorage.getItem('configurationArr') && JSON.parse(localStorage.getItem('configurationArr'));
    // 是否显示训练营，任务，测评 有效期
		let Task_interface_optimize = configurationArr && configurationArr.filter((item) => {
				if (item.key === 'Task_interface_optimize') {
					return true
				}
			})[0];
		this.Task_interface_optimize = Task_interface_optimize && Task_interface_optimize.value === 'true' ? true : false;
    if(this.Task_interface_optimize){
			await this.taskDetailWithoutLogin();
    }else{
			this.getTaskDetail();
		}
		let task_show_facedialog_rule = configurationArr && configurationArr.filter((item) => {
			if (item.key === 'task_show_facedialog_rule') {
					return item
			}
		})[0];
		this.task_show_facedialog_rule = task_show_facedialog_rule && JSON.parse(task_show_facedialog_rule.value);
	},
	beforeRouteLeave (to, from, next) {
		if(to.name!='coursePlay'){
				sessionStorage.setItem('isFaceByTask',0)
				localStorage.setItem('currentCourseId','')
		}
		next();
	},
  destroyed () {

    sessionStorage.removeItem('ishint');
	},
	mounted() {},
	methods: {
		showFaceDialog(){
			//人脸识别范围：任务，课程，素材 。后端开始人脸识别间隔时间针对的是素材,配置项默认开启的人脸识别范围是course
			if(this.task_show_facedialog_rule.range=='task' && this.taskDetail.studyProcess<100 && sessionStorage.getItem('isFaceByTask')!=='1' && this.taskRuleInfo.isCourseFaceRecognize){ //人脸识别范围是任务
					if(this.task_show_facedialog_rule.isOnce){//人脸识别为一次性配置,开启一次性弹窗后，不再弹出。
							this.viewFaceAccess({
									type:1,
									taskId:this.taskRuleInfo.taskId
							}).then(res=>{
									if(res.data==0){//未弹出过人脸识别
											this.confirmdialog.show = true;
											this.verification = true;
									}
							})
					}else{
							this.confirmdialog.show = true;
							this.verification = true;
					}
			}
		},
		// 查看是否已保存人脸识别访问记
		viewFaceAccess(obj){
				const data ={
						...obj
				}
				return new Promise((resolve,reject)=>{
						this.$api.learning.viewFaceAccess(data).then(res=>{
								resolve(res)
						}).catch(err=>{
								reject(err)
						})
				})
		},
    // 任务规则
    async findTaskRule() {
			await this.$api.examination.findfaceNeed(this.$route.query.id).then((res) => {
        this.taskRuleInfo = res.data || null;
			})
		},
    taskTiphandleClose(){
      this.taskTipDialogVisible = false;
    },
		goBack() {
			if (this.$route.query.backPath) {
				sessionStorage.setItem('userCenterCurrentTab', '我的学习')
				this.$router.push({
					path: this.$route.query.backPath,
					query: {
						goodsId: this.$route.query.goodsId,
						goodsKind: this.$route.query.goodsKind,
						isNeedBreadcrumb:this.$route.query.isNeedBreadcrumb
					},
				})
			} else {
				this.$router.push({
					path: '/learning',
					query: {
						backModule: this.$route.query.backMoudle,
					},
				})
			}
		},
    // 初始化详情
    async taskDetailWithoutLogin(){
      const params = {
				id: this.$route.query.id || null,
				version: 1, ////兼容接口版本
			}
			await this.$api.learning.taskDetailWithoutLogin({ params }).then(async(res) => {
				if (!res.data && res.message === '用户尚未添加到该培训任务') {
					this.$router.go(-1)
					return false
				}
        if(res.data){
					this.taskDetail = res.data;
					this.showFaceDialog();
          if(this.taskDetail.taskItemVoList.length){
            this.taskDetail.taskItemVoList.forEach((item) => {
                if (item.type == 4) {
                  this.isHaveLive = true
                }
            })
          }
          await this.taskDetailExt();
        }
			})
    },
    // /** 遍历大纲 返回学习相关信息 */
    changeTaskTree(data,data2){
      data.forEach(item => {
        data2.forEach(item2 => {
          if(item.id === item2.id){
              item2.studyProcess = item.studyProcess;
              item2.studyInfo = item.studyInfo;
          }
          // 添加作业
          if(item.homeworkId&&item.courseId === item2.contentId){
              item2.childItemList.push(item)
          }
          if(item2.childItemList && item2.childItemList.length){
              this.changeTaskTree(data,item2.childItemList);
          }
        })
        if(item.childItemList && item.childItemList.length){
            this.changeTaskTree(item.childItemList,data2);
        }
      });
    },
    // 2次渲染详情 学习相关信息
    taskDetailExt(){
      let params = {
        id:this.$route.query.id || null
      }
      this.$api.learning.taskDetailExt({params}).then(async res => {
        if(res.data){
          this.taskInfo = {
            ...this.taskInfo,
            learnTotalTime:res.data.learnTotalTime,
            courseNum:res.data.courseNum || 1,
            studyProcess:res.data.studyProcess
          };
          let tree = [];
          for(let key in res.data.itemInfos){
            tree.push(res.data.itemInfos[key])
          }
          await this.changeTaskTree(tree,this.taskDetail.taskItemVoList);
          console.log('tree====',tree);
          console.log('this.taskList',this.taskDetail.taskItemVoList)
        }
      })
    },
		getTaskDetail() {
			const params = {
				id: this.$route.query.id || null,
				version: 1, ////兼容接口版本
			}
			this.$api.learning.taskDetail({ params }).then((res) => {
				if (!res.data && res.message === '用户尚未添加到该培训任务') {
					this.$router.go(-1)
					return false
				}
				this.taskDetail = res.data;
				this.showFaceDialog();
				this.taskDetail.taskItemVoList.length &&
					this.taskDetail.taskItemVoList.forEach((item) => {
						if (item.type == 4) {
							this.isHaveLive = true
						}
					})
			})
		},
	},
}
</script>
<style lang="stylus" scoped src="../css/taskDetail.styl"></style>
