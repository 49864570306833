<template>
	<div>
		<div class="courseItem courseItem0" v-for="courseItem in courseDetail.childItemList" :key="courseItem.id">
			<!-- 作业 -->
			<template v-if="courseItem.homeworkId">
				<div class="work" @click="toWork(courseItem)">
					<div class="courseContentItem_left">
						<svg-icon icon-class="study_zuoye"></svg-icon>

						<span>{{ courseItem.homeworkName }}</span>
					</div>
					<div class="courseContentItem_right">
						<span v-if="courseItem.status == 0">{{$t('WDNPC_WEB_NOT_SUBMITTED',{text:'未提交'})}}</span>
						<span class="highLight" v-else-if="courseItem.status == 1">{{$t('WDNPC_WEB_SUBMITTED',{text:'已提交'})}}</span>
						<span class="remarked" v-else>{{$t('WDNPC_WEB_COMMENTED',{text:'已点评'})}}</span>
					</div>
				</div>
			</template>
            
			<!-- 没有目录 -->
			<template v-else-if="!hasCourseCatalog(courseItem)">
				<CourseContentItem
					:taskId="courseDetail.taskId"
					:courseItem="courseItem"
					:currentCourse="currentCourse"
					:isFaceRecognize="isFaceRecognize"
					:lastStudyId="lastStudyId"
					:courseDetail="courseDetail"
					:taskLearnRate="taskLearnRate"
				></CourseContentItem>
			</template>

			<template v-else>
				<!-- 第一个目录 -->
				<el-collapse class="course-1" v-model="courseActive1" accordion>
					<el-collapse-item :name="courseItem.id">
						<template #title>
							<div class="courseTitle course-1Title">
								<span class="circle"></span>
								<span class="name">{{ courseItem.contentName }}</span>
							</div>
						</template>

						<div class="courseItem courseItem1" v-for="item1 in courseItem.childItemList" :key="item1.id">
							<template v-if="!hasCourseCatalog(item1)">
								<CourseContentItem
									:taskId="courseDetail.taskId"
									:courseItem="item1"
									:currentCourse="currentCourse"
									:isFaceRecognize="isFaceRecognize"
									:lastStudyId="lastStudyId"
									:courseDetail="courseDetail"
									:taskLearnRate="taskLearnRate"
								></CourseContentItem>
							</template>

							<!-- 第二个目录 -->
							<template v-else>
								<el-collapse class="course-1-1" v-model="courseActive2" accordion>
									<el-collapse-item :name="item1.id">
										<template #title>
											<div class="courseTitle course-1-1Title">
												<span class="circle"></span>
												<span class="name">{{ item1.contentName }}</span>
											</div>
										</template>

										<div class="courseItem courseItem2" v-for="item2 in item1.childItemList" :key="item2.id">
											<template v-if="!hasCourseCatalog(item2)">
												<CourseContentItem
													:taskId="courseDetail.taskId"
													:courseItem="item2"
													:currentCourse="currentCourse"
													:isFaceRecognize="isFaceRecognize"
													:lastStudyId="lastStudyId"
													:courseDetail="courseDetail"
													:taskLearnRate="taskLearnRate"
												></CourseContentItem>
											</template>

											<!-- 第三个目录 -->
											<template v-else>
												<el-collapse class="course-1-1-1" v-model="courseActive3" accordion>
													<el-collapse-item :name="item2.id">
														<template #title>
															<div class="courseTitle course-1-1-1Title">
																<span class="circle"></span>
																<span class="name">{{ item2.contentName }}</span>
															</div>
														</template>

														<div class="courseItem courseItem3" v-for="item3 in item2.childItemList" :key="item3.id">
															<template v-if="!hasCourseCatalog(item3)">
																<CourseContentItem
																	:taskId="courseDetail.taskId"
																	:courseItem="item3"
																	:currentCourse="currentCourse"
																	:isFaceRecognize="isFaceRecognize"
																	:lastStudyId="lastStudyId"
																	:courseDetail="courseDetail"
																	:taskLearnRate="taskLearnRate"
																></CourseContentItem>
															</template>
														</div>
													</el-collapse-item>
												</el-collapse>
											</template>
										</div>
									</el-collapse-item>
								</el-collapse>
							</template>
						</div>
					</el-collapse-item>
				</el-collapse>
			</template>
		</div>
	</div>
</template>

<script>
import CourseContentItem from './courseContentItem'
export default {
	name: 'CourseItem',
	data() {
		return {
			courseActive1: '',
			courseActive2: '',
			courseActive3: '',
			isFaceRecognize: null,
		}
	},
	props: {
		courseDetail: Object,
		currentCourse: Object,
		defaultUnfold: {
			//默认展开
			type: Object,
			default: () => ({
				courseActiveNamea: '',
				courseActiveNameaa: '',
				courseActiveNameaaa: '',
			}),
		},
		lastStudyId: [Number, String],
		taskLearnRate:[Number, String]
	},
	components: {
		CourseContentItem,
	},
	watch: {
		defaultUnfold: {
			handler: function() {
				this.courseActive1 = this.defaultUnfold.courseActiveNamea
				this.courseActive2 = this.defaultUnfold.courseActiveNameaa
				this.courseActive3 = this.defaultUnfold.courseActiveNameaaa
			},
			deep: true,
		},
	},
	mounted() {
		this.findfaceNeed()
	},
	methods: {
		// 作业
		toWork(courseItem) {
			this.$router.push({
				path: '/learning/assignment',
				query: {
					courseId: this.courseDetail.contentId,
					homeworkId: courseItem.homeworkId,
					taskId: this.courseDetail.taskId,
				},
			})
		},
		hasCourseCatalog(courseItem) {
			if (courseItem.childItemList && courseItem.childItemList.length) {
				return true
			} else {
				return false
			}
		}, //查询培训任务规则
		async findfaceNeed() {
			await this.$api.examination.findfaceNeed(this.courseDetail.taskId).then((res) => {
				this.isFaceRecognize = res.data.isCourseFaceRecognize
			})
		},
	},
}
</script>

<style lang="stylus" scoped>
@import '../css/courseItem.styl'
</style>
