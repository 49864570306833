<template>
    <!-- multiple="multiple" -->
   
    <div class="upload-handle" v-if="status!=1&& status!=2" >
         <input
            type="file"
            :accept="accept"
            ref="file"
            @change="handleChange"
            style="visibility: hidden;margin-top:-20px;"
        >
        <svg-icon icon-class="add"  slot="default"></svg-icon>
        <span class="upload-tips-answer">{{$t('WDNPC_WEB_ADD_VIDEO',{text:'添加视频'})}}</span>
    </div>
</template>

<script>

export default {
    name: 'zuoyeUploadVideo',
    props: {
        status:{
            type: Number,
            default: 0,
        },
        maxSize: {
            type: Number,
            default: 0,
        },
        fileType: {
            type: Number,
            default: 1,
        },
        accept: {
            type: String,
            default: 'video/*'
        },
    },
    data() {
        return {
            listFiles: [],
            loading: false,
        };
    },
    mounted() {
    },
    methods: {
        handleClickDel(index) {
            this.uploader.deleteFile(index);
            this.listFiles.splice(index, index + 1);
        },
        handleClick() {
            this.$refs.file.click();
        },
        createUploader() {
            const self = this;
            // eslint-disable-next-line no-undef
            const uploader = new AliyunUpload.Vod({
                timeout: 600000,
                partSize: 1048576,  //分片大小默认1 MB，不能小于100 KB
                parallel: 5,  // 并行上传分片个数，默认5
                retryCount: 3, // 网络原因失败时，重新上传次数，默认为3
                retryDuration: 2,  // 网络原因失败时，重新上传间隔时间，默认为2秒
                userId: 1923859927839944,
                addFileSuccess() {
                    this.loading = true;
                },
                onUploadstarted(uploadInfo) {
                    if (!uploadInfo.videoId) {
                        self.$api.zuoyeResource.getUploadVideoParam(`fileName=${uploadInfo.file.name}&fileType=${self.fileType}`).then(({ data }) => {
                            const { uploadAuth } = data;
                            const { uploadAddress } = data;
                            const { videoId } = data;
                            uploader.setUploadAuthAndAddress(
                                uploadInfo,
                                uploadAuth,
                                uploadAddress,
                                videoId,
                            );
                        });
                    } else {
                        self.$api.zuoyeResource.refreshUploadVideo(`videoId=${uploadInfo.videoId}`).then(({ data }) => {
                            const { uploadAuth } = data;
                            const { uploadAddress } = data;
                            uploader.setUploadAuthAndAddress(
                                uploadInfo,
                                uploadAuth,
                                uploadAddress,
                                // videoId,
                            );
                        });
                    }
                },
                onUploadSucceed(uploadInfo) {
                    console.log('onUploadSucceed')
                    self.uploadSucceed(uploadInfo);
                },
                onUploadFailed(uploadInfo) {
                    self.uploadFailed(uploadInfo);
                },
                onUploadCanceled(uploadInfo) {
                    console.log('onUploadCanceled')
                    this.loading = false;
                    self.uploadCancel(uploadInfo)
                },
                onUploadProgress(uploadInfo, totalSize, loadedPercent) {
                    self.setUploadProgress(
                        uploadInfo.ri,
                        loadedPercent,
                        totalSize,
                    );
                },
                onUploadTokenExpired(uploadInfo) {
                    console.log('onUploadTokenExpired')
                    self.$api.zuoyeResource.refreshUploadVideo(`videoId=${uploadInfo.videoId}`).then(({ data: { result } }) => {
                        const uploadAuth = result.upload_auth;
                        uploader.resumeUploadWithAuth(uploadAuth);
                    });
                },
                onUploadEnd() {
                    console.log('onUploadEnd')
                    self.uploadEnd();
                },
            });
            
            return uploader;
        },
        checkFile(list) {
            const files = Array.from(list).filter(v => v.size <= this.maxSize);

            return new Promise((resolve, reject) => {
                if (!files.length) {
                    this.$message.error(this.$t('WDNPC_WEB_THE_SIZE_OF_THE_VIDEO_ATTACHMENT_CANNOT',{text:'视频附件大小不得超过'}) + '500M');
                    reject();
                }
                resolve();
            });
        },
        parseData(listFiles) {

            return listFiles.map(v => ({
                ri: v.ri,
                name: v.file.name,
                size: this.byteToM(v.file.size),
                beforeName:this.beforeFomate(v.file.name),
				afterName:this.afterFomate(v.file.name)
            }));
        },
        byteToM(byte) {
            return (byte / (1024 * 1024)).toFixed(2);
        },
        beforeFomate(name){
            let beforeName = ''
            var count =  name.split('.').length
            name.split('.').forEach((ite,ind) => {
                if(ind <= count - 2){
                    if(ind == count - 2){
                        beforeName += ite
                    }else{
                        beforeName += ite + '.'
                    }
                }
            })
            console.log('item',beforeName)
            return beforeName;
        },
        afterFomate(name){
            let afterName = ''
            var count =  name.split('.').length
            afterName = name.split('.')[count-1]
            console.log('item',afterName)
            return afterName
        },
        setUploadProgress(ri, loadedPercent) {
            const { listFiles } = this;
            let index = 0;
            let percent = loadedPercent;
            let loadedSize = 0;
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < this.listFiles.length; i++) {
                if (listFiles[i].ri === ri) {
                    index = i;
                }
            }
            loadedSize = (listFiles[index].size * percent).toFixed(2);
            percent = Math.floor(percent * 100);
            this.$set(
                listFiles,
                index,
                Object.assign(listFiles[index], { percent, loadedSize, index: index }),
            );

            this.$emit('progress-list', listFiles);
        },
        abortUpload(obj,index) {
            this.uploader.cancelFile(index)
            this.$emit('on-upload-cancel', obj);
        },
        uploadSucceed(info) {
            console.log('info',info)
            this.$emit('on-end', info);
        },
        uploadFailed(info) {
            this.$message.error(this.$t('WDNPC_WEB_UPLOAD_FAILED',{text:'上传失败'}));
            this.loading = false;
            console.log('listFiles',info)
            this.$emit('on-upload-fail-status',this.listFiles);
            this.uploader.cleanList();
            this.listFiles = [];
        },
        uploadEnd() {
            this.loading = false;
            this.$emit('on-upload-status');
            this.uploader.cleanList();
        },
        uploadCancel(val) {
            console.log('取消',val)
            this.loading = false;
            // this.$emit('on-upload-cancel', val);
        },
        handleChange(e, files) {
            const fileList = files || e.target.files;
            if (this.uploader) {
                this.uploader.stopUpload();
            }
            if (!fileList.length) return;

            let reg = /\.(mp4|avi|wmv|mov|flv|rmvb|3gp|m4v|mkv)$/i
            for (let i = 0; i < fileList.length; i += 1) {
                if(!reg.test(fileList[i].name)) {
                    fileList[i].size
                    this.$message.warning(this.$t('WDNPC_WEB_THE_FILE_FORMAT_IS_INCORRECT',{text:'文件格式有误'}));
                    return;
                }
                if (fileList[i].size > this.maxSize) {
                    this.$message.warning(this.$t('WDNPC_WEB_THE_FILE_SIZE_EXCEEDS_THE_LIMIT',{text:'文件大小超出限制'}));
                    return;
                }
            }


            if (fileList.length <= 10) {
                // 新建上传
                this.uploader = this.createUploader();
                Array.prototype.slice
                    .call(fileList, 0, fileList.length)
                    .forEach((v) => {
                        this.uploader.addFile(
                            v,
                            null,
                            null,
                            null,
                            '{"Vod":{}}',
                        );
                    });
                this.checkFile(fileList).then(() => {
                    this.listFiles = this.parseData(this.uploader.listFiles());
                    this.$emit('upload-status',this.listFiles);
                    this.uploader.startUpload();
                    this.loading = true;
                    this.$emit('on-upload-status', true);
                });
                e.target.value = null;
            } else {
                this.$message.error(this.$t('WDNPC_WEB_YOU_CAN_UPLOAD_A_MAXIMUM_OF_FILES',{text:'最多可上传10个文件'}));
            }
        },
    },
};
</script>
<style lang="stylus" scoped>
@import '../../css/assignment-submit.styl'
</style>