import { render, staticRenderFns } from "./courseDetail.vue?vue&type=template&id=262e8f50&scoped=true"
import script from "./courseDetail.vue?vue&type=script&lang=js"
export * from "./courseDetail.vue?vue&type=script&lang=js"
import style0 from "../css/courseDetail.styl?vue&type=style&index=0&id=262e8f50&prod&lang=stylus&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "262e8f50",
  null
  
)

export default component.exports