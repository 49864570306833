<!-- 
 * @description: 提交，编辑，可修改操作页面
 * @fileName: assignment-submit.vue 
 * @author:ninghf 
 * @date: 2021-07-30 16:24:59
!-->
<template>
    <div class="assignment-submit">
        <div class="backButton" @click="goBack">
            <span>
                <i class="back el-icon-arrow-left"></i>
                {{$t('WDNPC_WEB_RETURN',{text:'返回'})}}
            </span>
        </div>
        <div class="assignment-content">
            <div class="assignment-remark" v-if="obj.comment">
                 <div class="assignmentcom-header">
                    <svg-icon icon-class="remark"></svg-icon>
                    <span>{{$t('WDNPC_WEB_TEACHER_COMMENTS',{text:'老师点评'})}}</span>
                </div>
                <div class="assignmentcom-content">
                    <p class="content-text">
                        {{obj.comment}}
                    </p>
                </div>
                <img src="../../../assets/images/common/passed.png" alt="" class="remark-status-icon" v-if="obj.status==1">
                <img src="../../../assets/images/common/unpass.png" alt="" class="remark-status-icon" v-if="obj.status==2">
            </div>
            <div class="assignment-question">
                 <img src="../../../assets/images/common/passed.png" alt="" class="remark-status-icon" v-if="obj.status==1 && !obj.comment ">
                <img src="../../../assets/images/common/unpass.png" alt="" class="remark-status-icon" v-if="obj.status==2&&!obj.comment">
                <div class="assignmentcom-header">
                    <svg-icon icon-class="zuoye-title"></svg-icon>
                    <span>{{$t('WDNPC_WEB_HOMEWORK_QUESTIONS',{text:'作业题目'})}}</span>
                </div>
                <div class="assignmentcom-content">
                    <p class="content-text">{{obj.content}}</p>                    
                    <ul class="content-accessory" style="margin-right:-10px;">
                        <li v-for="(item) in homeworkResource" :key="item.id">
                            <div class="accessory-box">
                                <div class="accessory-top">
                                    <div class="top-icon">
                                        <svg-icon icon-class="pic" v-if="item.fileType==4"></svg-icon>
                                        <svg-icon icon-class="shipin" v-else-if="item.fileType==1"></svg-icon>
                                        <svg-icon icon-class="doc" v-else-if="item.fileType==3"></svg-icon>
                                        <svg-icon icon-class="zip" v-else-if="item.fileType==6"></svg-icon>
                                    </div>
                                      <p class="top-text" v-if="item.name.length<18">{{item.name}}</p>
                                      <p class="top-text" v-else>{{item.name.substring(0,15)}}...{{item.name.substring(item.name.lastIndexOf("."))}}</p>
                                </div>
                                <div :class="(item.transcodingStatus==3 || item.transcodingStatus==1) ? 'accessory-bottom fail':'accessory-bottom'">
                                    <el-button type="button"  @click="handlePreviewQuestion(item)" v-if="item.transcodingStatus==2" :class="item.fileType==6?'is-disabled':''">{{$t('WDNPC_WEB_PREVIEW',{text:'预览'})}}</el-button>
                                    <el-button type="button"  v-if="item.transcodingStatus==1" :disabled="true">{{$t('WDNPC_WEB_TRANSCODING',{text:'转码中'})}}...</el-button>
                                    <el-button type="button"  v-if="item.transcodingStatus==3" :disabled="true">{{$t('WDNPC_WEB_TRANSCODING_FAILED',{text:'转码失败'})}}</el-button>
                                    <el-button type="button" @click="handleDownloadQuestion(item)" v-if="item.transcodingStatus==2">{{$t('WDNPC_WEB_DOWNLOAD',{text:'下载'})}}</el-button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="assignment-answer">
                <div class="assignmentcom-header">
                    <svg-icon icon-class="answer"></svg-icon>
                    <span>{{$t('WDNPC_WEB_STUDENT_ANSWER',{text:'学员作答'})}}</span>
                </div>
                <div class="assignmentcom-content">
                     <div class="answer-module" v-if="picList.length ||picListFinished.length ||  (obj.submitTag && !isUpdate && !obj.status)|| (obj.status && picListFinished.length) || (!obj.submitTag && !isUpdate)">
                        <div class="answer-module-header">
                            <span>{{$t('WDNPC_WEB_PICTURE_ATTACHMENT',{text:'图片附件'})}}</span>
                            <span>{{$t('WDNPC_WEB_THE_SUPPORTED_FORMAT_SIZE_CANNOT_EXCEED_THE',{text:'（支持jpg、jpeg、png、gif、bmp格式，大小不超过5M，附件个数不超过限制10个）'})}}</span>
                        </div>
                        <div class="answer-module-content">
                           <div style="width:100px" v-if="obj.status==0 && !isUpdate || obj.submitTag!=1"  @click.stop="openInput(1)">
                                <zuo-upload-img  
                                ref="uploadPicInput"
                                :maxSize="tipBox[0].maxSize"
                                :accept="tipBox[0].accept"
                                :fileType="4"
                                @file-obj="getPicObj"
                                :status="Number(obj.status)"
                                @fileloadingdata="picloadingdata"
                                @openInput="openInput(1)"
                                ></zuo-upload-img>
                           </div>
                           <div class="upload-view">
                                <ul class="list-img" v-if="picList.length">
                                    <li v-for="(item,index) in picList" :key="item.id || item.index+'key'" class="list__item">
                                        <svg-icon icon-class="pic-plc"></svg-icon>
                                        <el-progress :percentage="Number(item.percent)" :stroke-width="2" :show-text="false" :color="'#1972FF'"></el-progress>
                                        <div class="pic-action">
                                            <span @click="delResource(item, index, 1)">{{$t('WDNPC_WEB_CANCEL_UPLOAD',{text:'取消上传'})}}</span>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="list-img" v-if="picListFinished.length">
                                    <li v-for="(item,index) in picListFinished" :key="item.id || index+'key'" class="list__item">
                                        <img :src="item.url" alt="" @error="imgError(item.url,index)">
                                        <div class="pic-action">
                                            <span @click="handlePreviewQuestion(item)">{{$t('WDNPC_WEB_PREVIEW',{text:'预览'})}}</span>
                                            <span class="line-palceholder" v-if="(obj.status!=0 || isUpdate )"></span>
                                            <span @click="handleDownloadQuestion(item)" v-if="(obj.status!=0 || isUpdate )">{{$t('WDNPC_WEB_DOWNLOAD',{text:'下载'})}}</span>
                                        </div>
                                        <div class="pic-del"  v-if="obj.status==0 && !isUpdate" @click="handleDelResource(item,index,1)">
                                            <svg class="icon" aria-hidden="true">
                                                <use xlink:href="#ic-guanbi"></use>
                                            </svg>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="answer-module" v-if="videoList.length || videoListFinished.length ||  (obj.submitTag && !isUpdate && !obj.status)|| (obj.status && videoListFinished.length) || (!obj.submitTag && !isUpdate)">
                        <div class="answer-module-header">
                            <span>{{$t('WDNPC_WEB_VIDEO_ATTACHMENT',{text:'视频附件'})}}</span>
                            <span>{{$t('WDNPC_WEB_THE_SUPPORTED_FORMAT_SIZE_CANNOT_EXCEED_THE_CYY',{text:'（支持mp4，avi，wmv，mov，flv，rmvb，3gp，m4v，mkv格式，大小不超过500M，附件个数不超过限制10个）'})}}</span>
                        </div>
                        <div class="answer-module-content">
                             <div style="width:100px" v-if="obj.status==0 && !isUpdate || obj.submitTag!=1"  @click.stop="openInput(2)">
                               <zuo-upload-video  
                                ref="uploadVideoInput"
                                :fileType="1"
                                :maxSize="tipBox[1].maxSize"
                                :accept="tipBox[1].accept"
                                :status="Number(obj.status)" 
                                @upload-status="handleUploadVideoStatus" 
                                @progress-list="getVideoList" 
                                @openInput="openInput(2)"
                                @on-end="onUploadEnd"
                                @on-upload-status="handleChangeStatus"
                                @on-upload-fail-status="handleChangeFailStatus"
                                @on-upload-cancel="onUploadCancel"
                            ></zuo-upload-video>
                           </div>
                            <div class="upload-view"> 
                                <ul class="content-accessory" v-if="videoListFinished.length>0">
                                    <li v-for="(item,index) in videoListFinished" :key="item.id ||index+'key'">
                                        <div class="accessory-box">
                                            <div class="accessory-top">
                                                <div class="top-icon">
                                                    <svg-icon icon-class="shipin"></svg-icon>
                                                </div>
                                                <p class="top-text" v-if="item.name.length<18">{{item.name}}</p>
                                                <p class="top-text" v-else>{{item.name.substring(0,15)}}...{{item.name.substring(item.name.lastIndexOf("."))}}</p>
                                            </div>
                                            <div :class="(item.transcodingStatus==3 || item.transcodingStatus==1) ? 'accessory-bottom fail':'accessory-bottom'">
                                                <el-button type="button" v-if="item.transcodingStatus==2" @click="handlePreviewQuestion(item)">{{$t('WDNPC_WEB_PREVIEW',{text:'预览'})}}</el-button>
                                                <el-button type="button" v-if="item.transcodingStatus==1 || (item.transcodingStatus==null && item.percent)" :disabled="true">{{$t('WDNPC_WEB_TRANSCODING',{text:'转码中'})}}...</el-button>
                                                <el-button type="button" v-if="item.transcodingStatus==3" :disabled="true">{{$t('WDNPC_WEB_TRANSCODING_FAILED',{text:'转码失败'})}}</el-button>
                                                <el-button type="button" :disabled="false" @click="handleDelResource(item,index,2)" v-if="obj.status==0 && !isUpdate">{{$t('WDNPC_WEB_DELETE',{text:'删除'})}}</el-button>
                                                <el-button type="button" :disabled="false"   @click="handleDownloadQuestion(item)"  v-if="item.transcodingStatus==2 && (obj.status!=0 || isUpdate )">{{$t('WDNPC_WEB_DOWNLOAD',{text:'下载'})}}</el-button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="content-accessory" v-if="videoList.length>0">
                                    <li v-for="(item,index) in videoList" :key="item.id ||index+'key'">
                                        <div class="accessory-box">
                                            <div class="accessory-top">
                                                <div class="top-icon">
                                                    <svg-icon icon-class="shipin"></svg-icon>
                                                </div>
                                                <p class="top-text" v-if="item.name.length<18">{{item.name}}</p>
                                                <p class="top-text" v-else>{{item.name.substring(0,15)}}...{{item.name.substring(item.name.lastIndexOf("."))}}</p>
                                            </div>
                                            <div class="accessory-bottom" v-if="item.percent<100">
                                                <el-button type="button" :disabled="true">{{$t('WDNPC_WEB_UPLOADING',{text:'正在上传'})}}({{item.percent}}%)</el-button>
                                                <el-button type="button" @click="delResource(item, index, 2)">{{$t('WDNPC_WEB_CANCEL_UPLOAD',{text:'取消上传'})}}</el-button>
                                            </div>
                                            <el-progress :percentage="Number(item.percent)" :stroke-width="2" :show-text="false" :color="'#1972FF'" v-if="item.percent<100"></el-progress>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                     <div class="answer-module" v-if="fileList.length || fileListFinished.length || (obj.submitTag && !isUpdate && !obj.status)|| (obj.status && fileListFinished.length) || (!obj.submitTag && !isUpdate)">
                        <div class="answer-module-header">
                            <span>{{$t('WDNPC_WEB_FILE_ATTACHMENT',{text:'文件附件'})}}</span>
                            <span>{{$t('WDNPC_WEB_THE_SUPPORTED_FORMAT_SIZE_CANNOT_EXCEED_THE_TFO',{text:'（支持Excel、Word、PPT、PDF、zip格式，大小不超过100M，附件个数不超过限制10个）'})}}</span>
                        </div>
                        <div class="answer-module-content">
                            <div style="width:100px" v-if="obj.status==0 && !isUpdate || obj.submitTag!=1"  @click.stop="openInput(3)">
                              <zuo-upload-doc  
                                 :status="Number(obj.status)"
                                ref="uploadFileInput"
                                :maxSize="tipBox[2].maxSize"
                                :accept="tipBox[2].accept"
                                :fileType="3"
                                 @file-obj="getFileObj"
                                 @on-upload-cancel-file="onUploadCanceFile"
                                @openInput="openInput(3)"
                                @fileloadingdata="fileloadingdata"
                                v-if="obj.status==0 && !isUpdate || obj.submitTag!=1">
                            </zuo-upload-doc>
                           </div>
                            <div class="upload-view"> 
                                 <ul class="content-accessory" v-if="fileListFinished.length>0">
                                    <li v-for="(item,index) in fileListFinished" :key="item.id ||index+'key'">
                                        <div class="accessory-box">
                                            <div class="accessory-top">
                                                <div class="top-icon">
                                                    <svg-icon icon-class="zip" v-if="item.fileType==6 || item.afterName=='zip'"></svg-icon>
                                                    <svg-icon icon-class="doc" v-else></svg-icon>
                                                </div>
                                                <p class="top-text" v-if="item.name.length<18">{{item.name}}</p>
                                                <p class="top-text" v-else>{{item.name.substring(0,15)}}...{{item.name.substring(item.name.lastIndexOf("."))}}</p>
                                            </div>
                                            <div :class="(item.transcodingStatus==3 || item.transcodingStatus==1) ? 'accessory-bottom fail':'accessory-bottom'" >
                                                <el-button type="button" v-if="item.transcodingStatus==2" @click="handlePreviewQuestion(item)" :class="item.fileType==6?'is-disabled':''">{{$t('WDNPC_WEB_PREVIEW',{text:'预览'})}}</el-button>
                                                <el-button type="button" v-if="item.transcodingStatus==1 ||(item.transcodingStatus==null && item.percent)" :disabled="true">{{$t('WDNPC_WEB_TRANSCODING',{text:'转码中'})}}...</el-button>
                                                <el-button type="button" v-if="item.transcodingStatus==3" :disabled="true">{{$t('WDNPC_WEB_TRANSCODING_FAILED',{text:'转码失败'})}}</el-button>
                                                <el-button type="button"  @click="handleDownloadQuestion(item)"  :disabled="false" v-if="item.transcodingStatus==2 && (obj.status!=0 || isUpdate )">{{$t('WDNPC_WEB_DOWNLOAD',{text:'下载'})}}</el-button>
                                                <el-button type="button" @click="handleDelResource(item,index,3)" v-if="obj.status==0 && !isUpdate">{{$t('WDNPC_WEB_DELETE',{text:'删除'})}}</el-button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="content-accessory" v-if="fileList.length>0">
                                    <li v-for="(item,index) in fileList" :key="item.id ||index+'key'">
                                        <div class="accessory-box">
                                            <div class="accessory-top">
                                                <div class="top-icon">
                                                    <svg-icon  icon-class="zip" v-if="item.afterName=='zip'"></svg-icon>
                                                     <svg-icon v-else icon-class="doc"></svg-icon>
                                                </div>
                                                <p class="top-text" v-if="item.name.length<18">{{item.name}}</p>
                                                <p class="top-text" v-else>{{item.name.substring(0,15)}}...{{item.name.substring(item.name.lastIndexOf("."))}}</p>
                                            </div>
                                            <div class="accessory-bottom " v-if="item.percent<100">
                                                <el-button type="button" :disabled="true">{{$t('WDNPC_WEB_UPLOADING',{text:'正在上传'})}}({{item.percent}}%)</el-button>
                                                <el-button type="button" @click="delResource(item, index, 3)">{{$t('WDNPC_WEB_CANCEL_UPLOAD',{text:'取消上传'})}}</el-button>
                                            </div>
                                            <el-progress :percentage="Number(item.percent)" :stroke-width="2" :show-text="false" :color="'#1972FF'" v-if="item.percent<100"></el-progress>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="answer-module" v-if="obj.message ||(obj.submitTag && !isUpdate && !obj.status)|| (!obj.submitTag && !isUpdate) ">
                        <div class="answer-module-header">
                            <span>{{$t('WDNPC_WEB_JOB_MESSAGE',{text:'作业留言'})}}</span>
                        </div>
                        <div class="answer-module-content">
                            <el-input type="textarea"   
                                :placeholder="$t('WDNPC_WEB_SUBMIT_A_MESSAGE_AS_AN_ATTACHMENT_FOR',{text:'作业以附件形式提交，留言仅做备注使用哟'})"
                                v-model="obj.message"
                                maxlength="500"
                                show-word-limit
                                :disabled="obj.status==1 || obj.status==2 || isUpdate"
                            ></el-input>
                        </div>
                    </div>
                </div>
                <div class="assignment-submit-button" v-if="obj.status!=1 && obj.status!=2">
                    <el-button type="primary" @click="updateHomeWork()" v-if="obj.submitTag==1 && isUpdate">{{$t('WDNPC_WEB_UPDATE_JOB',{text:'更新作业'})}}</el-button>
                    <el-button type="primary" @click="answerHomework()" v-else>{{$t('WDNPC_WEB_SUBMIT_A_JOB',{text:'提交作业'})}}</el-button>
                </div>
            </div>
        </div>
        <previewdialog :cur-row="previewObj" ref="previewdialog" :dialogPreviewVisible="dialogPreviewVisible"></previewdialog>
        <el-dialog
        class="warning-dialog"
        :visible.sync="warningdialogVisible"
        width="400px"
        >
        <svg-icon icon-class="tips" class-name="icon-img"></svg-icon>
        <p class="titles">{{$t('WDNPC_WEB_PROMPT',{text:'提示'})}}</p>
        <p class="text">{{$t('WDNPC_WEB_WHETHER_TO_SUBMIT_THE_CURRENT_JOB_CONTENT',{text:'是否提交当前作业内容？'})}}</p>
        <div slot="footer" class="dialog-footer">
            <el-button type="default" style="width:120px;" @click="warningdialogVisible = false"
            >{{$t('WDNPC_WEB_CANCEL',{text:'取消'})}}</el-button
            >
            <el-button type="primary" style="width:120px;background:#316FFF;border:none;" @click="submitHomeWork"
            >{{$t('WDNPC_WEB_OK',{text:'确定'})}}</el-button
            >
        </div>
        </el-dialog>
    </div>
</template>
<script>
import zuoUploadImg from  './components/upload-img.vue'
import zuoUploadVideo from  './components/upload-video.vue'
import zuoUploadDoc from  './components/upload-doc.vue'
import previewdialog from './components/preview-dialog.vue'
export default {
    name:'assignment',
    data(){
        return{
            picList:[],
            picListFinished:[],// 图片上传成功
            uploadPicList: [],
            uploadPicStatus:false,//图片上传成功状态
            uploadPicFlag: false,//是否存在图片标识

            uploadVideoFlag: false,//是否存在视频标识
            videoList:[],//视频上传
            videoListFinished: [],//视频上传成功
            uploadVideoList: [],

            fileList: [],// 文档上传
            uploadFileFlag: false,//是否存在文档标识
            fileListFinished:[],// 文档上传成功
            uploadFileList: [],
            uploadFileStatus:false,//文档上传成功状态
            previewObj:{},
            obj:{
                transcodingStatus:'',//0 未转码，1 转码中, 2 转码成功， 3 转码失败
                message:'留言',    // 
                comment:'',//点评       
                content:'作业内容',//作业内容

                status:'', //(0 未批改、1 通过、2 不通过)
                submitTag:0,//0 未提交，1 已提交
                 //fileType:文件类型：1.video视频,2.audio音频,3.文档,4.图片,6.scorm 跟之前资源一样
            },
             tipBox: [
          {
            id: 1,
            tip1: this.$t('WDNPC_WEB_THE_SUPPORTED_FORMAT_SIZE_CANNOT_EXCEED_THE_XYJ',{text:'支持jpg、jpeg、png、gif、bmp格式，大小不超过5M，附件个数不超过限制10个'}),
            accept: ".jpg,.jpeg,.png,.gif,.bmp",
            maxSize: 1024 * 1024 * 5
          },
          {
            id: 2,
            tip1:this.$t('WDNPC_WEB_THE_SUPPORTED_FORMAT_SIZE_CANNOT_EXCEED_THE_FFT',{text:'支持mp4，avi，wmv，mov，flv，rmvb，3gp，m4v，mkv格式，大小不超过500M，附件个数不超过限制10个'}),
            accept: ".mp4,.avi,.wmv,.mov,.flv,.rmvb,.3gp,.m4v,.mkv",
            maxSize: 1024 * 1024 * 500
          },
          {
            id: 3,
            tip1: this.$t('WDNPC_WEB_THE_SUPPORTED_FORMAT_SIZE_CANNOT_EXCEED_THE_KEC',{text:'支持Excel、Word、PPT、PDF、zip格式，大小不超过100M，附件个数不超过限制10个'}),
            accept: ".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.zip",
            maxSize: 1024 * 1024 * 100
          },
        ],

            isUpdate:false,//是否是可更新狀態
            homeworkResource:[],
            answerResource:[],
            courseId:null,
            homeworkId: null,
            dialogPreviewVisible:false,
            warningdialogVisible:false,
        }
    },
    components:{
        zuoUploadImg,
        zuoUploadVideo,
        zuoUploadDoc,
        previewdialog
    },
    created() {
        this.findHomework();
    },
    mounted(){
        
    },
    methods:{
    //图片重新加载
    imgError(url,index) {
        let img = document.querySelectorAll('.list__item')[index].children[0];
        img.src = url
    },
  openInput(type) {
          if (type == 2) {
            // this.uploadVideoFlag = true
            if(!this.uploadVideoStatus){
              if(this.uploadVideoList.length > 9){
                this.$message.error(this.$t('WDNPC_WEB_THE_NUMBER_OF_FILES_CANNOT_EXCEED',{text:'文件个数不能超过10个'}));
              }else{
                this.$refs.uploadVideoInput.handleClick();
              }
            }else{
              this.$message.warning(this.$t('WDNPC_WEB_THE_FILE_IS_BEING_UPLOADED_PLEASE_OPERATE',{text:'文件正在上传中，请稍后操作'}));
            }
          } else if (type == 3) {
            if(!this.uploadFileStatus){
              if(this.uploadFileList.length > 9){
                this.$message.error(this.$t('WDNPC_WEB_THE_NUMBER_OF_FILES_CANNOT_EXCEED',{text:'文件个数不能超过10个'}));
              }else{
                this.$refs.uploadFileInput.handleClick();
              }
            }else{
              this.$message.warning(this.$t('WDNPC_WEB_THE_FILE_IS_BEING_UPLOADED_PLEASE_OPERATE',{text:'文件正在上传中，请稍后操作'}));
            }
          } else {
            if(!this.uploadPicStatus){
              if(this.uploadPicList.length > 9){
                this.$message.error(this.$t('WDNPC_WEB_THE_NUMBER_OF_FILES_CANNOT_EXCEED',{text:'文件个数不能超过10个'}));
              }else{
                this.$refs.uploadPicInput.handleClick();
              }
            }else{
              this.$message.warning(this.$t('WDNPC_WEB_THE_FILE_IS_BEING_UPLOADED_PLEASE_OPERATE',{text:'文件正在上传中，请稍后操作'}));
            }
          }
      },

        getPicObj(data){
        this.uploadPicList.push({
          ...data
        });
        console.log("$t('WDNPC_WEB_UPLOAD_IMAGES',{text:'上传图片'})11111", this.uploadPicList);
      },
        picloadingdata(data) {
        console.log("data", data);
        // 找到已经在上传的文件索引，有就替换，没有就新增
        const sameIndex = this.picList.findIndex(
          item => item.index === data.index
        );
        if (sameIndex !== -1) {
          if (data.isDelete) {
            this.picList.splice(sameIndex, 1);
          } else {
            this.picList.splice(sameIndex, 1, data);
          }
        } else {
          this.picList.push(data);
        }
        this.picList.forEach(item => {
          if (item.index === data.index) {
            this.$set(item, "percent", data.percent);
          }
          const status = item.percent < 100;
          this.$set(item, "uploadStatus", status);
        });
  
        const isAllFinished = this.picList.every(
          item => item.percent === 100
        );
  
        if (isAllFinished) {
          this.afterUpload(1);
          this.uploadPicFlag = false;
           this.uploadPicStatus = false;
        } else {
          this.uploadPicFlag = true;
           this.uploadPicStatus = true;
        }
      },
        delResource(item, index, type) {
            if (type == 2) {
            const indexs = this.videoList.findIndex(it => it.ri === item.ri);
            this.videoList.splice(indexs, 1);
            this.$refs.uploadVideoInput.abortUpload(item, index);
            } else if (type == 3) {
            const indexs = this.fileList.findIndex(it => it.ri === item.ri);
            this.uploadFileList.splice(indexs, 1);
            this.$refs.uploadFileInput.abortUpload(item,index);
            } else {
            const indexs = this.picList.findIndex(it => it.ri === item.ri);
            this.picList.splice(indexs, 1);
            this.$refs.uploadPicInput.abortUpload(item);
            }
        },
        handleUploadStatus(fileList) {
            if (this.videoListFinished.length > 0) {
            this.uploadVideoFlag = true;
            } else {
            this.uploadVideoFlag = true;
            this.videoList = fileList;
            }
        },
        //视频列表数据
        handleUploadVideoStatus(fileList){
             if (this.videoListFinished.length > 0) {
            this.uploadVideoFlag = true;
            } else {
            this.uploadVideoFlag = true;
            this.videoList = fileList;
            }
        },
        //删除
        handleDelResource(item,index,type) {
        if(type == 1){
          if (!this.picList.length) {
            this.uploadPicList.splice(index, 1);
            this.picListFinished.splice(index, 1);
          } else {
            this.$message.warning(this.$t('WDNPC_WEB_PLEASE_WAIT_UNTIL_THE_RESOURCE_IS_BEING',{text:'请稍候，资源正在上传'}));
          }
        }else if(type == 2){
          if (!this.videoList.length) {
            this.uploadVideoList.splice(index, 1);
            this.videoListFinished.splice(index, 1);
          } else {
            this.$message.warning(this.$t('WDNPC_WEB_PLEASE_WAIT_UNTIL_THE_RESOURCE_IS_BEING',{text:'请稍候，资源正在上传'}));
          }
        } else if(type == 3){
          if (!this.fileList.length) {
            this.uploadFileList.splice(index, 1);
            this.fileListFinished.splice(index, 1);
          } else {
            this.$message.warning(this.$t('WDNPC_WEB_PLEASE_WAIT_UNTIL_THE_RESOURCE_IS_BEING',{text:'请稍候，资源正在上传'}));
          }
        }
      },
        // 删除图片
        handleDelPic(index) {
            if (this.picList.length) {
                this.picList.splice(index, 1);
            }
        },
        // 视频取消
       onUploadCancel(val) {
            console.log('WDNPC_WEB_CANCEL_VIDEO_UPLO)',val, this.videoList);
            if (this.videoList.length > 0) {
            this.uploadVideoStatus = true;
            } else {
            this.uploadVideoStatus = false;
            }
        },
        // 文档上传取消
        onUploadCanceFile(){
            if (this.fileList.length > 0) {
                this.uploadFileStatus = true;
            } else {
                this.uploadFileStatus = false;
            }
        },
        // 视频上传完成
        onUploadEnd({ file: { name, size }, videoId }) {
            this.uploadVideoList.push({
            name: name,
            videoId: videoId,
            fileType:1,
            bucketName:'',
            size:size,
            url:'',
            });
            console.log("$t('WDNPC_WEB_UPLOAD_VIDEO',{text:'上传视频'})444444444", this.uploadVideoList);
            this.successFlag = true;
        },
        // 视频失败上传
        handleChangeFailStatus(info) {
            this.uploadVideoFlag = false;
            this.videoList = info;
            this.uploadVideoStatus = false;
            // if (!this.uploadFailStatus) {
            this.afterUpload(2);
            // }
        },
        handleChangeStatus(key) {
            this.uploadVideoStatus = key || false;
            this.uploadVideoFlag = key || false;
            if (!this.uploadVideoFlag) {
            this.afterUpload(2);
            
            }
        },
        getFileObj(data) {
            this.uploadFileList.push({
            ...data,
            });
            this.uploadFileList.forEach(item => {
            let count =  item.name.split('.').length
            let afterName = item.name.split('.')[count-1]
            if(afterName == 'zip'){
                item.fileType = 6
            }else{
                item.fileType = 3
            }
            })
        },
        getVideoList(data){
            this.videoList= data;
        },
        fileloadingdata(data) {
            console.log("data", data);
            // 找到已经在上传的文件索引，有就替换，没有就新增
            const sameIndex = this.fileList.findIndex(
            item => item.index === data.index
            );
            if (sameIndex !== -1) {
            if (data.isDelete) {
                this.fileList.splice(sameIndex, 1);
            } else {
                this.fileList.splice(sameIndex, 1, data);
            }
            } else {
            this.fileList.push(data);
            }
    
            this.fileList.forEach(item => {
            if (item.index === data.index) {
                this.$set(item, "percent", data.percent);
            }
            const status = item.percent < 100;
            this.$set(item, "uploadStatus", status);
            });
    
            console.log('this.fileList',this.fileList);
            const isAllFinished = this.fileList.every(
            item => item.percent === 100
            );
    
            if (isAllFinished) {
            this.afterUpload(3);
            this.uploadFileFlag = false;
             this.uploadFileStatus = false;
            } else {
            this.uploadFileStatus = true;
            this.uploadFileFlag = true;
            }
        },
          afterUpload(type) {
        if(type == 1){
          this.picListFinished = this.picListFinished.concat(
            this.picList
          );
          this.$forceUpdate();
          this.picList = [];
        }else if(type == 2){
          this.videoListFinished = this.videoListFinished.concat(
            this.videoList
          );
          this.videoList = [];
        }else if(type == 3){
          this.fileListFinished = this.fileListFinished.concat(
            this.fileList
          );
          this.fileList = [];
        }
      },
        /**
         *@description:提交作业
         *@date: 2021-08-05 17:16:06
        */
       answerHomework(){
            const allTypeFileInfoDtoList = this.uploadPicList.concat(this.uploadVideoList,this.uploadFileList)
            // const allTypeFileInfoDtoList = [...this.videoList,...this.picList,...this.fileList];
         
            if(!this.uploadPicStatus && !this.uploadVideoStatus && !this.uploadFileStatus ){
                this.dialogContinuelVisible = true;
            }else{
                this.$message.warning(this.$t('WDNPC_WEB_THE_FILE_IS_BEING_UPLOADED_PLEASE_OPERATE',{text:'文件正在上传中，请稍后操作'}));
                return 
            }
            if(!allTypeFileInfoDtoList.length){
                this.$message.warning(this.$t('WDNPC_WEB_PLEASE_UPLOAD_THE_JOB_ATTACHMENT',{text:'请上传作业附件'}))
                return 
            }
            this.warningdialogVisible = true;
       },
       updateHomeWork(){
            this.isUpdate = false;
       },
       submitHomeWork(){
           console.log(this.uploadFileList);
           const data ={
                homeworkId:this.$route.query.homeworkId,
                courseId:this.$route.query.courseId,
                message:this.obj.message,
                taskId:this.$route.query.taskId,
                resourceList:{
                    allTypeFileInfoDtoList:this.uploadPicList.concat(this.uploadVideoList,this.uploadFileList),
                    saasType:0,
                    unitId:342
                }
                
            }
           this.$api.zuoye.answerHomework(data).then((res)=>{
                if(res.data){
                    this.warningdialogVisible = false;
                    this.$message.success(this.$t('WDNPC_WEB_SUBMITTED_SUCCESSFULLY',{text:'提交成功'}));
                }
                this.$router.go(-1);
            })
       },
        // 获取作业问题信息
        findHomework(){
            console.log(this.$route.query);
            const params = {
                homeworkId:this.$route.query.homeworkId,
                courseId:this.$route.query.courseId,
                taskId:this.$route.query.taskId
            }
            this.$api.zuoye.findHomework(params).then((res)=>{
                if(res.data){
                    this.obj = {...res.data}
                    this.homeworkResource = res.data.homeworkResource;
                    this.answerResource = res.data.answerResource;
                    this.listParseData(this.answerResource);
                    if(res.data.status==0&& res.data.submitTag==1){ //已提交未点评
                        this.isUpdate = true;
                    }else{
                        this.isUpdate = false;
                    }
                }
            })
        },
        listParseData(arr){
            this.videoList =[];
            this.picList = [];
            this.fileList = [];
            arr.forEach(element => {
                if(element.fileType==1){
                    this.uploadVideoFlag = true
                    this.videoListFinished.push(element)
                    this.uploadVideoList.push(element)
                }else if(element.fileType==4){
                    this.uploadPicFlag = true
                  // 图片
                  this.picListFinished.push(element)
                  this.uploadPicList.push(element)
                }else if(element.fileType==3 || element.fileType==6){
                  this.uploadFileFlag = true
                  this.fileListFinished.push(element)
                  this.uploadFileList.push(element)
                }
                
            });
        },
        // 上传后的视频
        handleUploadVideo(list){
            this.videoList = list;
        },
        //下载 
        handleDownloadQuestion(item){
            this.$api.zuoyeResource.downHomeworkFile(item.id).then(res => {
                if (res.data) {
                let fileUrl = res.data.fileUrl;
                // let index = res.data.fileName.split(".").length;
                // let fileName = res.data.fileName.split(".")[index - 1];
                // if (item.fileType === 4 || (item.fileType === 3 && fileName == "pdf")) {
                    var xhr = new XMLHttpRequest();
                    xhr.open("GET", fileUrl, true);
                    xhr.responseType = "blob";
                    xhr.onload = function(e) {
                    // console.debug('e',e)
                    if (e.target.readyState === 4 && e.target.status === 200) {
                        let blob = this.response;
                        // console.debug("blob", blob);
                        // 转换一个blob链接
                        let u = window.URL.createObjectURL(blob);
                        // console.debug(u);
                        let a = document.createElement("a");
                        a.download = res.data.fileName;
                        a.href = u;
                        a.style.display = "none";
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(u);
                    }
                    };
                    xhr.send();
                // } else {
                //     let a = document.createElement("a");
                //     a.download = res.data.fileName;
                //     console.log("a", a);
                //     a.href = fileUrl;
                //     a.style.display = "none";
                //     document.body.appendChild(a);
                //     a.click();
                //     a.remove();
                // }
                // xhr.onprogress()
                }
            });
        },
        // 预览

        handlePreviewQuestion(item){
            if(item.fileType==6){
                this.$message.warning(this.$t('WDNPC_WEB_PREVIEW_IS_NOT_SUPPORTED_IN_THE_CURRENT',{text:'当前格式暂不支持预览'}));
                return 
            }
            this.dialogPreviewVisible = true;
            this.$nextTick(() => {
                this.$refs.previewdialog.showPreviewPlayer({...item})
            })
            this.previewObj = {...item}
            
        },
        goBack(){
            this.$router.go(-1);
        },
    }
}
</script>
<style lang="stylus" scoped>
@import '../css/assignment-submit.styl'
</style>
