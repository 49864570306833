<template>
    <el-dialog
      title=""
      :visible.sync="dialogPreviewVisiblePlayer"
      :close-on-click-modal="false"
      :show-close="false"
      :class="{ 'dialog-pdf': curRow && curRow.fileType === 3}"
    >
      <div class="dialog-preview-content">
         <div class="closePreview" @click="handleClosePreview">
          <svg-icon icon-class="close-solid"></svg-icon>
       </div>

      <div  style="width:1000px;">
          <videoraasplayer
          ref="videoraasplayer"
          :cur-row="curRow"
          v-if="curRow && curRow.fileType < 3"
        />
          <div
          id="Vidpreview"
          v-if="curRow && curRow.fileType === 3"
          style="width: 100%;min-height: 600px;"
        ></div>
         <div :class="{ 'dialog-div-img': curRow && curRow.fileType === 4}">
            <img
            :src="curRow.url"     
            v-if="curRow && curRow.fileType === 4"
            class="resource-img"
            />
      </div>
      </div>
      </div>
    </el-dialog>
</template>
<script>
  export default {
    name: 'previewdialog',
    props: {
      curRow: {
        type: Object,
        default: () => {},
      },
      dialogPreviewVisible: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        dialogPreviewVisiblePlayer: this.dialogPreviewVisible,
        pdfUrl: '',
      }
    },
    watch: {
      dialogPreviewVisiblePlayer(val) {
        if (!val && this.$refs.videoraasplayer) {
          this.$refs.videoraasplayer.dispose()
        }
      },
    },
    methods: {
      handleClosePreview(){
        this.dialogPreviewVisiblePlayer = false;
        this.curRow.url ='';
      },
      showPreviewPlayer(row) {
        var params = ''
        // if (row.transcodingStatus === 2) {
          if (row.fileType < 3) {
            this.$api.zuoyeResource.previewMedias(row.id).then((res) => {
                if (res.success && res.data) {
                    params = {
                        format: 'm3u8',
                        mediaType: 'video',
                    }
                    // 视频，安全相关的
                    this.curVideoUrl = res.data
                    this.dialogPreviewVisiblePlayer = true
                    this.$nextTick(() => {
                        console.log('this.$refs.videoraasplayer',this.$refs.videoraasplayer)
                        if(res.data?.sourceSing && (res.data?.sourceSing=='222' || res.data.sourceSing=='333')){
                            // 三方，222 格莱森的，333交通的
                            this.$refs.videoraasplayer.initPlayer(res.data.playUrl,true)
                        }else{
                            this.$refs.videoraasplayer.initPlayer(this.curVideoUrl,false, params)
                        }
                    })
                }
            })
          } else if (row.fileType === 4) {
            this.dialogPreviewVisiblePlayer = true
          } else {
            // console.log('ccccccccccc')
            this.dialogPreviewVisiblePlayer = true
            this.$api.zuoyeResource.getImageOrDocUrls(row.id).then((res) => {
              if (res.success) {
                this.pdfUrl = res.data
  
                // 下边是安全才有的
                // eslint-disable-next-line no-undef
                let demo = aliyun.config({
                  mount: document.querySelector('#Vidpreview'),
                  url: res.data.previewURL, //设置文档预览URL地址。
                })
                console.log('demo', demo)
                // //设置AccessToken。
                demo.setToken({ token: res.data.accessToken })
                // ppt存在动画才有，否则会报错
                if (row.name.split('.')[1] === 'ppt') {
                  demo.PPTApplication().ActivePresentation.SlideShowSettings.Run()
                  demo.PPTApplication().ActivePresentation.Slides.Count
                }
              } else {
                // this.$message.error(res.message);
              }
            })
          }
        // } else {
        //   this.$message.warning('转码成功方可预览')
        // }
      },
    },
  }
  </script>
  <style lang="stylus" scoped>
  .resource-img
    max-width: 100%;
    max-height: 600px;
    display: inline-block;
    margin: 0 auto;
  .dialog-div-img
    background: #F7FAFE;
    height:600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
/deep/.el-dialog
  width:1000px;
  background:transparent;
  box-shadow:none;
  .dialog-preview-content
      position relative
      display:inline-block;
      .closePreview
          position absolute
          right 0;
          top -40px
          .icon
              font-size :24px;
              vertical-align middle
/deep/.el-dialog__header
    padding:0px;
/deep/.el-dialog__body 
    padding:0;
    text-align:center;
    img
        max-width:100%;
        vertical-align:middle;
        max-height:600px;
        min-width:100px;
  /deep/.el-dialog__headerbtn
        top:-24px;
        right:0px;
  </style>
  