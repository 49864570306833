<template>
	<div class="courseDetail">
		<el-collapse :class="['courseCollaps', className]" v-model="courseActiveName" accordion>
			<el-collapse-item :name="courseDetail.id">
				<!-- 课程名字 -->
				<template #title>
					<div class="courseAbstract">
						<div class="courseAbstracte_left">
							<span class="tag">
								{{$t('WDNPC_WEB_COURSE',{text:'课程'})}}
							</span>
							<span class="name" v-if="currentCourse.courseStatus != 8">{{ courseDetail.contentName }}</span>
							<span class="name" v-else>****** {{$t('WDNPC_WEB_SORRY_THIS_CONTENT_HAS_BEEN_FORCIBLY_TERMINATED',{text:'很抱歉，此内容已被强制终止'})}}</span>
						</div>
						<div class="courseAbstract_right">
							<span class="detail" @click="getcourseInfo(currentCourse.courseStatus, $event)">{{$t('WDNPC_WEB_COURSE_DETAILS',{text:'课程详情'})}}</span>
							<span class="cutLine"></span>
							<span>{{$t('WDNPC_WEB_COMPLETED',{text:'已完成：'})}}</span>
							<!-- 百分比 -->
							<template>
								<span :class="{ highLight: courseDetail.studyProcess }">
									{{ courseDetail.studyProcess || 0 }}%
								</span>
							</template>
							<!-- 个数 -->
							<!-- <template>
                  <span class="highLight">8</span>
                  <span>/</span>
                  <span>15</span>
              </template> -->
						</div>
					</div>
				</template>

				<div class="list">
					<CourseItem
						:courseDetail="courseDetail"
						:currentCourse="currentCourse"
						:defaultUnfold="defaultUnfold"
						:lastStudyId="lastStudyId"
            :isFaceRecognize="isFaceRecognize"
						:taskLearnRate="taskLearnRate"
					></CourseItem>
				</div>
			</el-collapse-item>
		</el-collapse>
		<DialogInfo :info="info" :currentCourse="currentCourse" :nameType="$t('WDNPC_WEB_COURSE_INTRODUCTION',{text:'课程简介：'})" :type="2" @infoShow="infoShow"></DialogInfo>
	</div>
</template>

<script>
import CourseItem from './courseItem'
import DialogInfo from './../../train/page/dialogInfo.vue'
export default {
	name: 'courseDetail',
	data() {
		return {
			courseActiveName: '',
			defaultUnfold: {
				courseActiveNamea: '',
				courseActiveNameaa: '',
				courseActiveNameaaa: '',
			},
			currentCourse: {},
			lastStudyId: '',
			isFaceRecognize: null,
			info: false,
			taskLearnRate:''
		}
	},
	props: {
		courseDetail: Object,
		className: String,
	},
	components: {
		CourseItem,
		DialogInfo,
	},
	async mounted() {
    this.findfaceNeed();
		// console.log(this.courseDetail, 'courseDetail================')
		// this.findCourseInfo()
		this.quickLearn()
		// this.findByCourseId()
	},
	methods: {
     //查询培训任务规则
     async findfaceNeed() {
        await this.$api.examination.findfaceNeed(this.$route.query.id).then(res=>{
            this.isFaceRecognize = res.data.isCourseFaceRecognize;
        })
    },
		findByCourseId() {
			const params = {
				courseId: this.courseDetail.contentId,
				taskId: this.courseDetail.taskId,
			}
			this.$api.zuoye.findByCourseId(params).then((res) => {
				if (res.data) {
					this.zuoyeList = res.data
				}
			})
		},
		defaultExpand() {
			var parentNode = null
			var node = null

			function getNode(json, target) {
				for (var i = 0; i < json.length; i++) {
					if (node) {
						break
					}
					var obj = json[i]
					if (!obj || !obj.id) {
						continue
					}

					if (obj.id == target) {
						node = obj
						if (obj.parentId || obj.parentId === 0) {
							parentNode = { id: obj.parentId }
						}
						break
					} else {
						if (obj.childItemList && obj.childItemList.length != 0) {
							getNode(obj.childItemList, target)
						} else {
							continue
						}
					}
				}
				if (!node) {
					parentNode = null
				}
				return {
					parentNode: parentNode,
					node: node,
				}
			}

			var result = []
			var getResult = function(json, query) {
				node = null
				parentNode = null
				var obj = getNode(json, query)
				if (obj.parentNode) {
					getResult(json, obj.parentNode.id)
					result.push(obj.parentNode.id)
				}
				return result
			}
			// getResult(this.catalogList, this.lastStudyId)
			getResult(this.courseDetail.childItemList, this.lastStudyId)
			console.log(result, 'result------------------------')

			if (result.length) {
				this.courseActiveName = this.courseDetail.id
				let str = 'courseActiveName'
				for (var i = 0; i < result.length; i++) {
					this.defaultUnfold[str] = result[i]
					str += 'a'
				}
				// 默认展开的目录id
				this.$emit('getLastStudyId', this.courseDetail.id)
			}
		},
		quickLearn() {
			const params = {
				taskId: this.courseDetail.taskId,
				taskType: 2,
			}
			return this.$api.course.quickLearn({ params }).then((res) => {
				if (res.data) {
					console.log(res.data, 'quicklearn__________________')
					this.lastStudyId = res.data.lastPlayItemId,
					this.taskLearnRate = res.data.taskLearnRate;
					// 默认展开上次学习内容
					this.defaultExpand()
				}
			})
		},
		// 课程详情
		async findCourseInfo() {
			let params = {
				courseId: this.courseDetail.contentId,
				taskId: this.courseDetail.taskId,
				taskType: this.courseDetail.type || null,
			}
			const { data } = await this.$api.training.findCourseInfo({ params })
			this.currentCourse = data
		},
		// 课程章节
		async findCourseItemByCourseId() {
			return this.$api.training
				.findCourseItemByCourseId({
					params: {
						courseId: this.courseDetail.contentId,
						taskId: this.courseDetail.taskId,
						taskType: this.courseDetail.type || null,
					},
				})
				.then((res) => {
					if (res.data) {
						this.catalogList = res.data
						// if (res.data.length) {
						// 	console.log(res.data, ' res.data')
						// 	this.expandedKeys.push(res.data[0].id)
						// }
					}
				})
		},
		// 课程简介
          async  getcourseInfo(status, e) {
           await this.findCourseInfo();
        //    await this.findByCourseId();
			e.stopPropagation()
			if (status === 8) {
				this.$message.error(this.$t('WDNPC_WEB_SORRY_THIS_CONTENT_HAS_BEEN_FORCIBLY_TERMINATED',{text:'很抱歉，此内容已被强制终止'}))
			} else {
				this.info = true
			}
		},
		// 弹窗
		infoShow(val) {
			this.info = val
		},
	},
}
</script>

<style lang="stylus" scoped src="../css/courseDetail.styl"></style>
