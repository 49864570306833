<template>
    <div class="learning-box">
        <div class="tab-container">
            <p class="text" v-for="item in tabList" :key="item.id" :class="{active : tabValue === item.key}" @click="tabClick(item)">
                {{$t(item.value)}}
                <span class="line"></span>
            </p>
        </div>
        <trainingTask v-if="tabValue === 'trainingTask'" :validity="validity ? validity.value : ''" :Task_interface_optimize="Task_interface_optimize" ></trainingTask>
        <trainingCamp v-if="tabValue === 'trainingCamp'" :validity="validity ? validity.value : ''"></trainingCamp>
        <evaluationList v-if="tabValue === 'evaluation'" :validity="validity ? validity.value : ''"></evaluationList>
        <exam-list v-if="tabValue === 'exam'"></exam-list>
        <liveList v-if="tabValue === 'live'"></liveList>
        <practiceList v-if="tabValue === 'test'"></practiceList>
    </div>
</template>
<script>
import practiceList from '../../practice/page/practiceList.vue'
export default {
    name:'learning',
    components:{
        practiceList
    },
    data(){
        return{
            tabValue:'',
            tabList:[
                // {
                //     id:1,
                //     key:'trainingTask',
                //     value:'培训任务'
                // },
                // {
                //     id:2,
                //     key:'trainingCamp',
                //     value:'训练营'
                // },
                // {
                //     id:3,
                //     key:'evaluation',
                //     value:'测评'
                // },
                // {
                //     id:4,
                //     key:'exam',
                //     value:'考试'
                // }
            ],
            validity:'',
            isI18nFlag:false,
            Task_interface_optimize:false
        }
    },
    created(){
        
        const configurationArr = JSON.parse( localStorage.getItem('configurationArr') )
        // tab 数组
        const learningTab = configurationArr && configurationArr.filter(item => {
            if(item.key === 'task_tag_array_learn_center'){
                return true
            }
        })[0];
        // 是否显示训练营，任务，测评 有效期
        this.validity =  configurationArr && configurationArr.filter(item => {
            if(item.key === 'is_show_task_term_validity'){
                return true
            }
        })[0];
        // 列表接口是否走新接口
        let Task_interface_optimize =  configurationArr && configurationArr.filter(item => {
            if(item.key === 'Task_interface_optimize'){
                return true
            }
        })[0];
         this.Task_interface_optimize = Task_interface_optimize.value && Task_interface_optimize.value === 'true' ? true : false
        console.log(this.Task_interface_optimize,'Task_interface_optimize')
        // this.validity = validity || ''
        this.tabList = learningTab && JSON.parse(learningTab.value) || []
        // 如果是国际化隐藏掉训练营和测评
        if(localStorage.getItem('is_i18n') == 'true'){
            this.tabList = [{
                key:'trainingTask',
                value:'WDNPC_WEB_RAINING_TASK', //培训任务
                id:1
            },
            {
                key:'exam',
                value:'WDNPC_WEB_EXAM', //考试
                id:4
            },{
                key:'live',
                value:'WDNPC_WEB_LIVE_STREAMING', //直播
                id:5
            }]
        }
        this.tabValue = this.$route.query.backModule || (this.tabList.length && this.tabList[0].key)
    },
    methods:{
        tabClick(item){
            this.tabValue = item.key;
        },
    }
    
}
</script>
<style lang="stylus" scoped>
@import '../css/learning.styl'
</style>