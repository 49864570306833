<template>
	<div class="courseContentItemWrapper">
		<div class="courseContentItem" :class="{ locked: courseItem.isLocked }" @click="toStudy">
			<!-- 0 目录；1，视频；2，音频；3，文档；4，图片；6，scorm；9，试卷；10，练习；11，直播；12，实训 -->
			<!-- 视频 -->
			<template v-if="courseItem.courseType === 1">
				<div class="courseContentItem_left">
					<svg-icon icon-class="study_mp4"></svg-icon>

					<span class="name">{{ courseItem.contentName }}</span>
					<span v-if="courseItem.dateExpireStatus == 2">{{$t('WDNPC_WEB_EXPIRED_AND_CANNOT_BE_VIEWED',{text:'已过期不可查看'})}}</span>

					<div class="currentStudy" v-show="courseItem.id === lastStudyId && showTootip" @click="handleClick($event)">
						<span>
							{{$t('WDNPC_WEB_I_LEARNED_HERE_BEFORE',{text:'之前学到这里啦'})}}！
						</span>
						<span>
							<svg-icon icon-class="tootip_close"></svg-icon>
						</span>
					</div>
				</div>
				<div class="courseContentItem_right duration">
					<span class="videoInfo" v-if="courseItem.duration">
						<span class="timeKey">{{$t('WDNPC_WEB_DURATION',{text:'时长'})}}</span>
						<span class="cutDot">·</span>
						<span class="timeVal">{{ minutes }}:{{ seconds }}</span>
					</span>
					<span class="highLight" v-if="courseItem.studyInfo && courseItem.studyInfo.learnRate">
						{{ courseItem.studyInfo.learnRate || 0 }}%
					</span>

					<svg-icon v-if="courseItem.isLocked" icon-class="lock"></svg-icon>
				</div>

				<div class="hoverTip" v-if="!courseItem.isLocked">
					{{$t('WDNPC_WEB_TO_LEARN',{text:'去学习'})}}
				</div>
			</template>
			<!-- 图片 -->
			<template v-else-if="courseItem.courseType === 4">
				<div class="courseContentItem_left">
					<svg-icon icon-class="study_img"></svg-icon>

					<span class="name">{{ courseItem.contentName }}</span>
					<span v-if="courseItem.dateExpireStatus == 2">{{$t('WDNPC_WEB_EXPIRED_AND_CANNOT_BE_VIEWED',{text:'已过期不可查看'})}}</span>

					<div class="currentStudy" v-show="courseItem.id === lastStudyId && showTootip" @click="handleClick($event)">
						<span>
							{{$t('WDNPC_WEB_I_LEARNED_HERE_BEFORE',{text:'之前学到这里啦'})}}！
						</span>
						<span>
							<svg-icon icon-class="tootip_close"></svg-icon>
						</span>
					</div>
				</div>
				<div class="courseContentItem_right">
					<span class="highLight" v-if="courseItem.studyInfo && courseItem.studyInfo.learnRate">
						{{ courseItem.studyInfo && courseItem.studyInfo.learnRate }}%
					</span>

					<svg-icon v-if="courseItem.isLocked" icon-class="lock"></svg-icon>
				</div>

				<div class="hoverTip" v-if="!courseItem.isLocked">
					{{$t('WDNPC_WEB_TO_LEARN',{text:'去学习'})}}
				</div>
			</template>
			<!-- 音频 -->
			<template v-else-if="courseItem.courseType === 2">
				<div class="courseContentItem_left">
					<svg-icon icon-class="study_mp3"></svg-icon>

					<span class="name">{{ courseItem.contentName }}</span>
					<span v-if="courseItem.dateExpireStatus == 2">{{$t('WDNPC_WEB_EXPIRED_AND_CANNOT_BE_VIEWED',{text:'已过期不可查看'})}}</span>

					<div class="currentStudy" v-show="courseItem.id === lastStudyId && showTootip" @click="handleClick($event)">
						<span>
							{{$t('WDNPC_WEB_I_LEARNED_HERE_BEFORE',{text:'之前学到这里啦'})}}！
						</span>
						<span>
							<svg-icon icon-class="tootip_close"></svg-icon>
						</span>
					</div>
				</div>
				<div class="courseContentItem_right duration">
					<span class="videoInfo" v-if="courseItem.duration">
						<span class="timeKey">{{$t('WDNPC_WEB_DURATION',{text:'时长'})}}</span>
						<span class="cutDot">·</span>
						<span class="timeVal">{{ minutes }}:{{ seconds }}</span>
					</span>
					<span class="highLight" v-if="courseItem.studyInfo && courseItem.studyInfo.learnRate">
						{{ courseItem.studyInfo.learnRate || 0 }}%
					</span>

					<svg-icon v-if="courseItem.isLocked" icon-class="lock"></svg-icon>
				</div>

				<div class="hoverTip" v-if="!courseItem.isLocked">
					{{$t('WDNPC_WEB_TO_LEARN',{text:'去学习'})}}
				</div>
			</template>
			<!-- 文档 -->
			<template v-else-if="courseItem.courseType === 3">
				<div class="courseContentItem_left">
					<svg-icon icon-class="study_doc"></svg-icon>

					<span class="name">{{ courseItem.contentName }}</span>
					<span v-if="courseItem.dateExpireStatus == 2">{{$t('WDNPC_WEB_EXPIRED_AND_CANNOT_BE_VIEWED',{text:'已过期不可查看'})}}</span>

					<div class="currentStudy" v-show="courseItem.id === lastStudyId && showTootip" @click="handleClick($event)">
						<span>
							{{$t('WDNPC_WEB_I_LEARNED_HERE_BEFORE',{text:'之前学到这里啦'})}}！
						</span>
						<span>
							<svg-icon icon-class="tootip_close"></svg-icon>
						</span>
					</div>
				</div>
				<div class="courseContentItem_right">
					<span class="highLight" v-if="courseItem.studyInfo && courseItem.studyInfo.learnRate">
						{{ courseItem.studyInfo && courseItem.studyInfo.learnRate }}%
					</span>

					<svg-icon v-if="courseItem.isLocked" icon-class="lock"></svg-icon>
				</div>

				<div class="hoverTip" v-if="!courseItem.isLocked">
					{{$t('WDNPC_WEB_TO_LEARN',{text:'去学习'})}}
				</div>
			</template>
			<!-- scorm -->
			<template v-else-if="courseItem.courseType === 6">
				<div class="courseContentItem_left">
					<svg-icon icon-class="study_doc"></svg-icon>

					<span class="name">{{ courseItem.contentName }}</span>
					<span v-if="courseItem.dateExpireStatus == 2">{{$t('WDNPC_WEB_EXPIRED_AND_CANNOT_BE_VIEWED',{text:'已过期不可查看'})}}</span>

					<div class="currentStudy" v-show="courseItem.id === lastStudyId && showTootip" @click="handleClick($event)">
						<span>
							{{$t('WDNPC_WEB_I_LEARNED_HERE_BEFORE',{text:'之前学到这里啦'})}}！
						</span>
						<span>
							<svg-icon icon-class="tootip_close"></svg-icon>
						</span>
					</div>
				</div>
				<div class="courseContentItem_right">
					<span class="highLight" v-if="courseItem.studyInfo && courseItem.studyInfo.learnRate">
						{{ courseItem.studyInfo && courseItem.studyInfo.learnRate }}%
					</span>

					<svg-icon v-if="courseItem.isLocked" icon-class="lock"></svg-icon>
				</div>

				<div class="hoverTip" v-if="!courseItem.isLocked">
					{{$t('WDNPC_WEB_TO_LEARN',{text:'去学习'})}}
				</div>
			</template>
			<!-- 练习 -->
			<template v-else-if="courseItem.courseType === 10">
				<div class="courseContentItem_left">
					<svg-icon icon-class="lianxi_gray" style="color:#666"></svg-icon>

					<span class="name">{{ courseItem.contentName }}</span>
					<span v-if="courseItem.dateExpireStatus == 2">{{$t('WDNPC_WEB_EXPIRED_AND_CANNOT_BE_VIEWED',{text:'已过期不可查看'})}}</span>

					<div class="currentStudy" v-show="courseItem.id === lastStudyId && showTootip" @click="handleClick($event)">
						<span>
							{{$t('WDNPC_WEB_I_LEARNED_HERE_BEFORE',{text:'之前学到这里啦'})}}！
						</span>
						<span>
							<svg-icon icon-class="tootip_close"></svg-icon>
						</span>
					</div>
				</div>
				<div class="courseContentItem_right">
					<svg-icon v-if="courseItem.isLocked" icon-class="lock"></svg-icon>
				</div>

				<div class="hoverTip" v-if="!courseItem.isLocked">
					{{$t('WDNPC_WEB_TO_LEARN',{text:'去学习'})}}
				</div>
			</template>

			<!-- 兼容老数据 -->
			<!-- 直播 -->
			<template v-else-if="courseItem.courseType === 11">
				<div class="courseContentItem_left">
					<span class="name">{{ courseItem.contentName }}</span>
					<span v-if="courseItem.dateExpireStatus == 2">{{$t('WDNPC_WEB_EXPIRED_AND_CANNOT_BE_VIEWED',{text:'已过期不可查看'})}}</span>

					<div class="currentStudy" v-show="courseItem.id === lastStudyId && showTootip" @click="handleClick($event)">
						<span>
							{{$t('WDNPC_WEB_I_LEARNED_HERE_BEFORE',{text:'之前学到这里啦'})}}！
						</span>
						<span>
							<svg-icon icon-class="tootip_close"></svg-icon>
						</span>
					</div>
				</div>
				<div class="courseContentItem_right">
					<span class="highLight" v-if="courseItem.isLiveReplay && courseItem.status === 2 && !courseItem.isLocked">观看回放</span>

					<svg-icon v-if="courseItem.isLocked" icon-class="lock"></svg-icon>
				</div>

				<div class="hoverTip" v-if="!courseItem.isLocked">
					{{$t('WDNPC_WEB_TO_LEARN',{text:'去学习'})}}
				</div>
			</template>
			<!-- 实训 -->
			<template v-else-if="courseItem.courseType === 12">
				<div class="courseContentItem_left">
					<span class="name">{{ courseItem.contentName }}</span>
					<span v-if="courseItem.dateExpireStatus == 2">{{$t('WDNPC_WEB_EXPIRED_AND_CANNOT_BE_VIEWED',{text:'已过期不可查看'})}}</span>

					<div class="currentStudy" v-show="courseItem.id === lastStudyId && showTootip" @click="handleClick($event)">
						<span>
							{{$t('WDNPC_WEB_I_LEARNED_HERE_BEFORE',{text:'之前学到这里啦'})}}！
						</span>
						<span>
							<svg-icon icon-class="tootip_close"></svg-icon>
						</span>
					</div>
				</div>
				<div class="courseContentItem_right">
					<svg-icon v-if="courseItem.isLocked" icon-class="lock"></svg-icon>
				</div>

				<div class="hoverTip" v-if="!courseItem.isLocked">
					{{$t('WDNPC_WEB_TO_LEARN',{text:'去学习'})}}
				</div>
			</template>
		</div>
		<facedialog
      v-if="verification"
      :confirmdialog="confirmdialog"
      :address="verificationAddress"
      :failAddress="verificationFailAddress"
      :type="1"
      :is_course_image_export="true"
      :pauseFrame="true"
      :businessId="businessId"
      :taskId="taskId"
		:resId="courseItem.detailId"
    ></facedialog>
	</div>
</template>

<script>
import { goLiveHome } from '../../live/common/livePlatform'
export default {
	name: 'courseContentItem',
	data() {
		return {
      confirmdialog:{
        show:true,
      },
			isLock: true,
			showTootip: true,
			verification: false,
			verificationAddress: {},
			verificationFailAddress: {},
      businessId:'',
			minutes: '--',
			seconds: '--',
			task_show_facedialog_rule:{ //人脸识别规则
                range:'course',
                isOnce:false
            }
		}
	},
	props: {
		courseItem: Object,
		courseDetail: Object,
		className: String,
		currentCourse: Object,
		isFaceRecognize: Number,
		taskId: Number,
		lastStudyId: [Number, String],
		taskLearnRate: [Number, String],
	},
	mounted() {
		const configurationArr = (sessionStorage.getItem('configurationArr') && JSON.parse(sessionStorage.getItem('configurationArr'))) || this.$store.state.dynamic.frontendConfig || [];
		let task_show_facedialog_rule = configurationArr && configurationArr.filter((item) => {
            if (item.key === 'task_show_facedialog_rule') {
                return item
            }
        })[0];
        this.task_show_facedialog_rule = task_show_facedialog_rule && JSON.parse(task_show_facedialog_rule.value);
		if (this.courseItem.duration) {
			this.minutes = Math.floor(this.courseItem.duration / 60)
			this.seconds = this.courseItem.duration % 60
			if (this.minutes < 10) {
				this.minutes = '0' + this.minutes
			}
			if (this.seconds < 10) {
				this.seconds = '0' + this.seconds
			}
		}
		// console.log('mounted---------------------', this.courseItem)
		// console.log('lastStudyId', this.lastStudyId)
	},
	methods: {
		//开始阶段人脸识别
        startFace(){
            if(!this.task_show_facedialog_rule.isOnce){
				this.verification = true;
				this.confirmdialog.show = true;
            }else{
                let obj = {};
                if(this.task_show_facedialog_rule.range=='task'){
                    obj = {
                        type:1,
                        taskId:this.taskId
                    }
                }else if(this.task_show_facedialog_rule.range=='course'){
                    obj = {
                        type:2,
                        taskId:this.taskId,
                        courseId:this.businessId,
                    }
                }else if(this.task_show_facedialog_rule.range=='video'){
                    obj = {
                        type:3,
                        taskId:this.taskId,
                        courseId:this.businessId,
                        resId:this.courseItem.detailId
                    }
                }
                this.viewFaceAccess(obj).then(res=>{
                    if(res.data==0){//未弹出过人脸识别
						this.verification = true;
						this.confirmdialog.show = true;
                    }else{
						this.goPlay();
					}
                })
            }
		},
		viewFaceAccess(obj){
            const data ={
                ...obj
            }
            return new Promise((resolve,reject)=>{
                this.$api.learning.viewFaceAccess(data).then(res=>{
                    resolve(res)
                }).catch(err=>{
                    reject(err)
                })
            })
        },
		handleClick(e) {
			e.stopPropagation()
			this.showTootip = false
		},
		goPlay(){
			this.$router.push({
				path: '/course/play',
				query: {
					trainId: this.taskId,
					courseId: this.courseItem.courseId,
					detailId: this.courseItem.detailId,
					studyProcess: this.courseItem.studyInfo ? this.courseItem.studyInfo.learnRate : '',
					courseName: this.currentCourse.name,
					taskType: 2 || null, //2：任务；1：训练营
					taskId: this.taskId,
					id: this.taskId,
					backPathName: this.$route.path,
					backMoudle: this.$route.query.backMoudle,
					backPath: this.$route.query.backPath,
				},
			})
		},
		toStudy() {
			if (this.courseItem.dateExpireStatus == 2 || this.currentCourse.courseExpireStatus == 2) {
				this.$message.info(this.$t('WDNPC_WEB_EXPIRED_AND_CANNOT_BE_VIEWED',{text:'已过期不可查看'}))
			} else {
				if (this.courseItem.isLocked) {
					this.$message.warning(this.$t('WDNPC_WEB_IF_THE_CONTENT_IS_NOT_ENABLED_THE',{text:'该内容未开启，开启条件为前面的内容学习进度达到'})+`${this.courseItem.lockCourseRate}%`)
					return false
				}
				if (this.isFaceRecognize) {
					this.businessId = this.courseItem.courseId;
					// this.verification = true;
					// this.confirmdialog.show = true;
					if(
						(this.task_show_facedialog_rule.range=='task' && this.taskLearnRate<100 && sessionStorage.getItem('isFaceByTask')!=='1')||
						(this.task_show_facedialog_rule.range=='course' && localStorage.getItem('currentCourseId')!=this.courseItem.courseId && this.courseDetail.studyProcess<100) ||
						(this.task_show_facedialog_rule.range=='video' && (!this.courseItem.studyInfo || (this.courseItem.studyInfo.learnRate || 0) < 95))
					){
						this.startFace();
					}else{
						this.goPlay();
					}
					this.verificationAddress = {
						path: '/course/play',
						query: {
							trainId: this.taskId,
							courseId: this.courseItem.courseId,
							detailId: this.courseItem.detailId,
							studyProcess: this.courseItem.studyInfo ? this.courseItem.studyInfo.learnRate : '',
							courseName: this.currentCourse.name,
							taskType: 2 || null, //2：任务；1：训练营
							taskId: this.taskId,
							id: this.taskId,
							backPathName: this.$route.path,
							backMoudle: this.$route.query.backMoudle,
							backPath: this.$route.query.backPath,
						},
					}
				} else {
					if (this.courseItem.courseType === 10 || this.courseItem.courseType === 12) {
						this.postCourseLearningLogSaveLog(this.courseItem)
					}
					if (this.courseItem.courseType === 10) {
						this.$router.push({
							path: '/practice',
							query: {
								practiceId: this.courseItem.detailId,
								pathName: this.$route.path,
								taskType: 2 || null,
								id: this.taskId,
                                courseId:this.courseItem.courseId,
								taskId: this.taskId,
								backMoudle: this.$route.query.backMoudle,
								backPath: this.$route.query.backPath,
							},
						})
					} else if (this.courseItem.courseType === 11) {
						//直播兼容老数据
						if (this.courseItem.liveStatus === 2) {
							this.$message.warning(this.$t('WDNPC_WEB_THE_LIVE_BROADCAST_HAS_ENDED',{text:'直播已结束'}))
							return
						}
						if (!this.courseItem.detailId) {
							this.$message.warning(this.$t('WDNPC_WEB_NOT_ASSOCIATED_WITH_LIVE_STREAMING_PLEASE_CHECK',{text:'暂未关联直播，请稍后查看'}))
						} else {
							goLiveHome(this, this.courseItem, this.courseItem.liveRoomId,localStorage.getItem('current_language'),localStorage.getItem('timezone'))
						}
					} else if (this.courseItem.courseType === 12) {
						//实训兼容老数据
						this.getdrillDetail(this.courseItem)
					} else {
						
						this.$router.push({
							path: '/course/play',
							query: {
								trainId: this.taskId,
								courseId: this.courseItem.courseId,
								detailId: this.courseItem.detailId,
								studyProcess: this.courseItem.studyInfo ? this.courseItem.studyInfo.learnRate : '',
								courseName: this.currentCourse.name,
								taskType: 2 || null, //2：任务；1：训练营
								taskId: this.taskId,
								id: this.taskId,
								backPathName: this.$route.path,
								backMoudle: this.$route.query.backMoudle,
								backPath: this.$route.query.backPath,
								courseItemId:this.courseItem.id,
							},
						})
					}
				}
			}
		},
		//课程保存
		async postCourseLearningLogSaveLog(courseItem) {
			const data = {
				courseId: courseItem.courseId, // 课程id
				taskId: this.taskId || '', // 训练营或任务
				taskType: 2 || null, //2：任务；1：训练营
				courseItemId: courseItem.id, // 素材对应id
				cur: 1, // 当前观看位置
				max: 1, // 视频/音频/文档页数
				pollingTime: 1, //循环调用接口时间
			}
			return await this.$api.course.postCourseLearningLogSaveLog({ data }).then((res) => {
				console.log(res)
			})
		},
		// 获取实训详情
		getdrillDetail(item) {
			this.$api.learning.getdrillDetail({ id: item.detailId }).then((res) => {
				if (res.data) {
					if (this.isFaceRecognize) {
						this.verificationAddress = {
							path: window.location.origin + `/training_detail?courseId=${res.data.courseId}&labId=${res.data.labId}`,
							query: {
								type: item.courseType,
							},
						}
					} else {
						window.open(window.location.origin + `/training_detail?courseId=${res.data.courseId}&labId=${res.data.labId}`)
					}
				}
			})
		},
	},
}
</script>

<style lang="stylus" scoped src="../css/courseContentItem.styl"></style>
