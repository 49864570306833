<template>
  <!-- 考试详情 -->
  <div class="courseTraining">
          <dialog-commission-equ  :urlH5Str="urlH5Str" :isShowDialog="isShowDialog"  :examID="String(examId)" :weixinCodeTokens="weixinCodeTokens" @equShow="equShow"></dialog-commission-equ>
      <div class="backButton">
      <span @click="goBack">
        <i class="back el-icon-arrow-left"></i>
        {{$t('WDNPC_WEB_RETURN',{text:'返回'})}}
      </span>
    </div>
    <div class="train-info">
      <div class="train-name" v-if="examDetailsTitle">
        {{ examDetailsTitle }}
      </div>
      <!-- <div>
        <span
          class="train-date"
          v-if="timeSlot && timeSlot != 0"
          >
          <span v-if="stratTime">
            {{ startTime.replace(/-/g,'.')}} {{ $t('WDNPC_WEB_TO',{text:'至'})}} {{endTime.replace(/-/g,'.')}}
          </span>
          <span v-else>
            {{endTime.split(' ')[0].replace(/-/g, '.')}}
          </span>
        </span>
        <span
          class="train-date"
          v-else
          >{{ $t('WDNPC_WEB_LONG_TERM_EFFECTIVE',{text:'长期有效'}) }}
        </span>
      </div> -->
    </div>
    <section>
      <aside>
        <img v-if="examInfo.backImage" :src="examInfo.backImage" alt="" />
        <img v-else src="../../../assets/images/common/exam-placeholder-img.png" alt="" />
      </aside>
      <article class="no-course">
          <!--  -->
        <div v-if="examInfo.showDebugButton" style="color:red;font-size:14px;margin-bottom:15px;">
            <!-- Windows系统请使用Chrome浏览器/360 安全浏览器（极速模式），Mac 系统请使用Safari 浏览器（ 13.0以上版本）。 -->
            {{$t('WDNPC_WEB_WE_RECOMMEND_THAT_YOU_USE_BROWSERSAFE_BROWSER',{text:'建议使用Chrome浏览器/360 安全浏览器（极速模式）'})}}
            <span style="color:rgb(45, 103, 255);cursor: pointer;" @click="dialogExamEquipment.show = true">{{$t('WDNPC_WEB_VIEW_DETAILS',{text:'查看详情'})}}</span>
        </div>
        <h2 class="no-course-title">
          <span class="course-name">{{ examInfo.name }}</span>
          <span class="course-type" v-if="examInfo.type && frontendConfig == 'true'">{{$t(ConstData.useType[examInfo.type].text)}}</span>
        </h2>
        <p class="exam-details-ico" v-if="[0,1,2].includes(examInfo.result)">
          <img :src="examInfo.result | examresult" />
           <span class="span" v-if="examInfo.result==2">
                {{$t('WDNPC_WEB_NO_ANSWER',{text:'未作答'})}}
            </span>
            <span v-else>
                <span v-if="examInfo.result==0" class="span bujigeColor">
                    {{$t('WDNPC_WEB_BUJIGE',{text:'不及格'})}}
                </span>
                <span v-else class="span jigeColor">
                    {{$t('WDNPC_WEB_JIGE',{text:'及格'})}}
                </span>
            </span>
        </p>
        <div class="exam-card" v-if="!examInfo.combination">
          <div class="exam-list">
            <div class="card-list">
              <p class="fraction">{{ examInfo.totalQuestionNum || 0 }}</p>
              <p class="title">{{$t('WDNPC_WEB_NUMBER_OF_GENERAL_QUESTIONS',{text:'总题数(道)'})}}</p>
              <img src="../../../assets/images/common/exam-num.png" alt="" />
            </div>
            <div class="card-list zfz">
              <p class="fraction">{{ examInfo.totalScore || 0 }}</p>
              <p class="title">{{$t('WDNPC_WEB_TOTAL_SCORE',{text:'总分值(分)'})}}</p>
              <img src="../../../assets/images/common/exam-score.png" alt="" />
            </div>
            <div class="card-list jgf">
              <p class="fraction">{{ examInfo.passingScore || 0 }}</p>
              <p class="title">{{$t('WDNPC_WEB_CUTOFF_SCORES_POINTS',{text:'及格线(分)'})}}</p>
              <img src="../../../assets/images/common/exam-pass.png" alt="" />
            </div>
            <div class="card-list kssc">
              <p class="fraction">{{ examInfo.duration || 0 }}</p>
              <p class="title">{{$t('WDNPC_WEB_EXAM_DURATION_MINUTES',{text:'考试时长(分钟)'})}}</p>
              <img src="../../../assets/images/common/exam-time.png" alt="" />
            </div>
            <div class="left"></div>
            <div class="right"></div>
          </div>
          <div class="exam-date">
            <span class="title">{{$t('WDNPC_WEB_EXAM_TIME',{text:'考试时间：'})}}</span>
            <span
              class="time"
              v-if="
                examInfo.isLimitTime==1 &&
                examInfo.examStartDate &&
                examInfo.examEndDate
              "
            >
              {{ examInfo.examStartDate.slice(0, -3) }}{{$t('WDNPC_WEB_TO',{text:'至'})}}{{
                examInfo.examEndDate.slice(0, -3)
              }}
            </span>
            <span
              class="time"
              v-else-if="
                examInfo.isLimitTime==2
              "
            >
             每月定期（{{examInfo.regularDate}}）
            </span>
            <span class="time" v-else>{{$t('WDNPC_WEB_LONG_TERM_EFFECTIVE',{text:'长期有效'})}}</span>
            <span class="over" v-show="examInfo.ifEnd === 1">{{$t('WDNPC_WEB_ENDED',{text:'已结束'})}}</span>
            <div class="exam-details-footer" v-if="examInfo.ifEnd !== 1">
              <div class="exam-details-exam" :class="examInfo.showDebugButton ? 'exam-details-width' : ''">
                <p v-if="examInfo.ifStart === 0" class="exam-details-btn nostart">
                  <span>{{$t('WDNPC_WEB_THE_EXAM_HAS_NOT_STARTED_YET',{text:'考试尚未开始'})}}</span>
                </p>
                <p
                  v-if="
                    examInfo.ifStart === 1 &&
                    examInfo.totalExamNum == 0 &&
                    examInfo.isLocked == true
                  "
                  class="exam-details-btn nobgc"
                  @click="examtip"
                >
                  <span>
                    <svg-icon icon-class="lock"></svg-icon>
                    {{$t('WDNPC_WEB_ENTER_THE_EXAMINATION_ROOM',{text:'进入考场'})}}</span
                  >
                </p>
                <p
                  v-if="
                    examInfo.ifStart === 1 &&
                    examInfo.totalExamNum == 0 &&
                    examInfo.isLocked == false
                  "
                  class="exam-details-btn"
                  @click="realName"
                >
                  <span>{{$t('WDNPC_WEB_ENTER_THE_EXAMINATION_ROOM',{text:'进入考场'})}}</span>
                </p>
                <p
                  v-if="
                    examInfo.ifStart === 1 &&
                    examInfo.leftExamNum === 0 &&
                    examInfo.totalExamNum !== 0
                  "
                  class="exam-details-btn nostart"
                >
                  <span>{{$t('WDNPC_WEB_NO_NUMBER_OF_EXAMS_LEFT',{text:'已无考试剩余次数'})}}</span>
                </p>
                <p
                  v-if="
                    examInfo.ifStart === 1 &&
                    examInfo.leftExamNum > 0 &&
                    examInfo.totalExamNum !== 0 &&
                    examInfo.isLocked == true
                  "
                  class="exam-details-btn nobgc"
                  @click="examtip"
                >
                  <span
                    >
                    <svg-icon icon-class="lock"></svg-icon>
                     {{$t('WDNPC_WEB_ENTER_THE_EXAMINATION_ROOM',{text:'进入考场'})}}
                  </span>
                </p>
                <p
                  v-if="
                    examInfo.ifStart === 1 &&
                    examInfo.leftExamNum > 0 &&
                    examInfo.totalExamNum !== 0 &&
                    examInfo.isLocked == false
                  "
                  class="exam-details-btn"
                  @click="realName"
                >
                  <span> {{$t('WDNPC_WEB_ENTER_THE_EXAMINATION_ROOM',{text:'进入考场'})}} </span>
                </p>
                <p v-if="examInfo.totalExamNum > 0" class="examChance">
                  {{$t('WDNPC_WEB_REMAINING_OPPORTUNITIES_FOR_EXAMINATION',{text:'考试剩余机会：'})}}{{ examInfo.leftExamNum }}{{$t('WDNPC_WEB_TOTAL',{text:'次/ 共'})}}{{
                    examInfo.totalExamNum
                  }}{{$t('WDNPC_WEB_TIMES',{text:'次'})}}
                </p>
              </div>
              <div class="exam-details-debug" v-if="examInfo.showDebugButton">
                <p @click="commissionEqu" class="exam-details-debugbtn">
                  <span>{{$t('WDNPC_WEB_DEBUGGING_EQUIPMENT',{text:'调试设备'})}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="history-list">
          <div class="title">
            <h2>
              <svg-icon icon-class="kaoshilishijilu"></svg-icon>{{$t('WDNPC_WEB_HISTORY_TEST_RECORD',{text:'历史考试记录'})}}
            </h2>
            <el-tooltip placement="left" effect="dark" popper-class="atooltip">
              <div slot="content" class="con">
                {{$t('WDNPC_WEB_THE_EXAM_RECORD_OF_ABNORMAL_EXIT_WILL',{text:'异常退出的考试，考试记录会延迟显示，请耐心等待。'})}}
              </div>
              <i class="el-icon-info tip-icon"></i>
            </el-tooltip>
          </div>
          <ul class="list-item">
            <el-scrollbar style="height: 100%">
              <div v-if="!noList">
                <li v-for="(item, index) in historyList" :key="item.id">
                  <div class="item">
                    <span class="num">{{ index | formartIndex }}</span>
                    <span class="date">{{ item.examStartTime }}</span>
                    <span class="time">{{$t('WDNPC_WEB_TIME',{text:'用时：'})}}{{ item.useDuration | timeFormatter }}</span>
                    <span class="score" v-if="item.scoreAuth">
                      <template v-if="item.isReadOver === 1">{{$t('WDNPC_WEB_SCORE',{text:'得分：'})}}</template>
                      <i v-if="!item.isReadOver"><span class="modify">{{$t('WDNPC_WEB_MARKING',{text:'阅卷中'})}}</span></i>
                      <i v-else><span class="score-num">{{item.score}}</span>{{$t('WDNPC_WEB_POINTS',{text:'分'})}}</i>
                      <span class="invalid" v-if="(item.paperType == 1 && item.invalidType) || (item.paperType == 2 && item.isReadOver && item.invalidType)">{{$t('WDNPC_WEB_EXCEPTION',{text:'（异常）'})}}</span>
                    </span>
                    <span class="score" v-if="!item.scoreAuth && !item.isReadOver">
                      <template v-if="item.isReadOver === 1">{{$t('WDNPC_WEB_SCORE',{text:'得分：'})}}</template>
                      <i><span class="modify">{{$t('WDNPC_WEB_MARKING',{text:'阅卷中'})}}</span></i>
                    </span>
                    <span v-if="item.cheat == 1 &&  item.isReadOver === 1" class="weigui">{{$t('WDNPC_WEB_VIOLATION',{text:'违规'})}}</span>
                    <!-- <svg-icon v-if="item.cheat == 1 &&  item.isReadOver === 1" icon-class="weigui1" class-name="icon-img"></svg-icon> -->
                  </div>
                  <div class="btn" @click="viewDetails(item)">{{$t('WDNPC_WEB_VIEW_DETAILS',{text:'查看详情'})}}</div>
                </li>
              </div>
              <div v-else class="list-none">
                <img src="../../../assets/images/common/nodata.png" alt="" />
                <p>{{$t('WDNPC_WEB_THERE_IS_NO_RECORD_OF_THE_EXAM',{text:'暂无考试记录，赶快去考试吧'})}}～</p>
              </div>
            </el-scrollbar>
          </ul>
        </div>
      </article>
    </section>
    <facedialog
      v-if="verification"
      :address="verificationAddress"
      :failAddress="verificationFailAddress"
      :type="1"
      :userPaperId="examId"
      :pauseFrame="true"
      :confirmdialog="confirmdialog"
      @CloseSuccessFacedialog="CloseSuccessFacedialog"
    >
    </facedialog>
    <realName v-if="realNameDialog"></realName>
    <el-dialog
      class="warning-dialog"
      :visible.sync="warningdialogVisible"
      width="400px"
    >
      <svg-icon icon-class="tips" class="icon-img"></svg-icon>
      <p class="titles">{{$t('WDNPC_WEB_PROMPT',{text:'提示'})}}</p>
      <p class="text">{{dialogCon}}</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 100%" @click="warningdialogVisible = false"
          >{{$t('WDNPC_WEB_GOT_IT',{text:'知道了'})}}</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      class="alertDialog"
      v-if="examDialogVisible"
      :visible.sync="examDialogVisible"
      :close-on-click-modal="false"
      :title="'提示'"
      @close="closedialog"
    >
      <div class="text">
        检测到您有一场正在进行中的考试，防止考试异常，考试期间切勿离开
      </div>
      <button class="btn" @click="ContinueExam">继续考试</button>
    </el-dialog>
    <dialogExamEquipment :dialogVisible="dialogExamEquipment" ></dialogExamEquipment>
  </div>
</template>
<script>
import dialogCommissionEqu from '../../train/page/dialogCommissionEqu'
import cercertificateIsShowStatus from "./../../../utils/certificateMixin";
import DialogCommissionEqu from '../../train/page/dialogCommissionEqu';
import SDK from '@wd/lowcode-pc'
import md5 from "js-md5";
import Vue from "vue";
export default {
  name: "examDetails",
  components: {
      dialogCommissionEqu,
    //   facedialog,
    //   realName,
      DialogCommissionEqu

  },
  data() {
    return {
      examDialogVisible:false,
       confirmdialog:{
          show:true,
        },
        dialogExamEquipment:{
            show:false,
        },
      frontendConfig:false,
      loopTimer: null,
      examInfo: {},
      historyList: [],
      noList: false,
      title: this.$route.query.title,
      timeSlots:'',
      timeSlot: this.$route.query.timeSlot,
      startTime: this.$route.query.startTime,
      endTime: this.$route.query.endTime,
      bussinessId: this.$route.query.bussinessId,
      examId: this.$route.query.examId,
      type: this.$route.query.type,
      tipcon: "",
      realNameDialog: false,
      verification: false,
      verificationAddress: {},
      verificationFailAddress: {},
      pathName: this.$route.query.pathName,
      backModule: this.$route.query.backMoudle,
      closeRealName: null,
      isFaceRecogBeforeExam: '',
      isFaceRecogDuringExam: '',
      isFaceRecogCommitExam: '',
      warningdialogVisible: false,
      dialogCon: '',// 弹框提示文案
      urlH5Str:'',
      weixinCodeTokens: '',
      isShowDialog:false,
      examBasicsInfo:null,
      examOrigin:1,//考试来源  0 小程序，1 电脑，2 H5'
      SuccessFace:false
    };
  },
  mixins: [cercertificateIsShowStatus],
  computed: {},
  filters: {
    examresult(val) {
      if (val === 0) {
        return require("../../../assets/images/common/bujige.png");
      } else if (val === 1) {
        return require("../../../assets/images/common/jige.png");
      } else if (val === 2) {
        return require("../../../assets/images/common/weizuoda.png");
      }
    },
    dataFormat(msg) {
      if (msg) {
        const date = new Date(msg);
        const m = date.getMonth() + 1;
        return date.getFullYear() + "." + m + "." + date.getDate();
      }
    },
    formartIndex(index) {
      if (index + 1 < 10) {
        return "0" + (index + 1);
      }
      return index + 1;
    },
    timeFormatter(time) {
      const hours = Math.floor(time / 60 / 60);
      const minutes = Math.floor((time / 60) % 60);
      const seconds = Math.floor(time % 60);
      var hours1 = hours < 10 ? "0" + hours : hours;
      var minutes1 = minutes < 10 ? "0" + minutes : minutes;
      var seconds1 = seconds < 10 ? "0" + seconds : seconds;
      return hours1 + '时' + minutes1 + '分' + seconds1 + '秒';
    },
  },
  watch: {
    'closeRealName'(val){
      if (val==false) {
        this.realNameDialog = false;
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.loopTimer)
  },
  created() {
    this.findExamListByIds();
    this.loopTimer = setInterval(() => {
       this.findExamListByIds();
       this.findUserPaperListById(); //历史记录同步更新，解决阅卷状态同步考试详情信息
    }, 30000)
    this.findUserPaperListById();
    sessionStorage.setItem( "examDetailsTitle", this.title );
    sessionStorage.setItem( "examDetailsTimeSlot", this.timeSlot );
    sessionStorage.setItem("examDetailStartTime",this.startTime)
    sessionStorage.setItem("examDetailendTime",this.endTime)
    this.examDetailsTitle = this.title;
    // this.examDetailsTimeSlot = this.timeSlots;
    if (this.pathName) {
      sessionStorage.setItem( "examPathName", this.pathName );
      sessionStorage.setItem( "examBackModule", this.backModule );
    }
    this.examPathName = this.pathName || sessionStorage.getItem( "examPathName" );
    this.examBackModule = this.backModule || sessionStorage.getItem( "examBackModule" );
    let frontendConfigList =  SDK.getConfigruation(this.$store).frontendConfig;
    frontendConfigList.map(item=>{
        if(item.key === 'isShowexamTag'){
            console.log('item---->', item)
            this.frontendConfig = item.value;
        }
    })
    console.log("0000000---->",SDK.getConfigruation(this.$store))
  },
  methods: {
      // 人脸识别成功
      CloseSuccessFacedialog(){
        console.log('人脸识别成功')
        this.SuccessFace = true;
      },
      // 继续考试
      ContinueExam(){
        this.joinExam()
        
      },
      closedialog(){
          this.examDialogVisible = false;
      },
      // 调试设备
      async commissionEqu () {
          console.log('设备调试++++++++99999')
          let CheckLiveParam = {
              channel: 1,
              businessId: this.$route.query.bussinessId,
              examId:this.$route.query.examId,
              businessType: this.$route.query.type
          }
          console.log(this.$route.query.examId,'this.$route.query.examId')
          if(localStorage.getItem('LiveParam')){
              localStorage.removeItem('LiveParam')
          }
          console.log(this.$route.query.bussinessId,'this.$route.query.bussinessIdthis.$route.query.bussinessId')
          localStorage.setItem('CheckLiveParam',JSON.stringify(CheckLiveParam))
          let localDomain = JSON.parse(window.localStorage.getItem('domain')).filter(item=>item.frontend_template_id=='4')
          let paramURL = 'https://' + localDomain[0].domain + '/device_check?'
          // let paramURL = 'https://hrss-stu.testing2.wdeduc.com' + '/device_check?'

          let qureyS =
              this.$route.query.examId+ '&='
              + this.$route.query.type + '&='
              + this.$route.query.bussinessId+ '&='
              + localStorage.getItem('organ')+ '&='
              + localStorage.getItem('actor')+ '&='
              + localStorage.getItem('token')+ '&='
              + localStorage.getItem('branch')
          let params =
              {
                  'token': qureyS
              }
          console.log('query1231231231',qureyS)
          console.log('this.businessId',this.businessId)

          let res = await this.$api.exam.saveTKData(params)
          // console.log(res.data,'-0==-=-=-=')
          this.urlH5Str = paramURL + 'tokens=' +res.data;
          this.weixinCodeTokens = res.data;
          console.log('弹出设备调试++++++++')
          this.isShowDialog = true
      },
      equShow(val){
          this.isShowDialog = val
      },
    // 返回
    goBack() {
      if (this.examPathName == '/learning') {
        this.$router.push({
          path: this.examPathName,
          query: {
            backModule:this.examBackModule
          },
        });
      } else if (this.examPathName == '/learning/taskDetail') {
        this.$router.push({
          path: this.examPathName,
          query: {
            id: this.bussinessId,
            type: this.$route.query.type
          },
        });
      } else if (this.examPathName == '/examList') {
        this.$router.push({
          path: this.examPathName,
          query: {},
        });
      }else if(this.examPathName === '/personalCenter' || this.examPathName === '/personalCenter_new'){
          sessionStorage.setItem('userCenterCurrentTab','我的学习')
          this.$router.push({
            path: this.examPathName,
        });
      }
    },
    // 获取考试详情
    findExamListByIds() {
      this.$api.examination
        .findExamListByIds(this.bussinessId, this.$route.query.examId, this.type)
        .then((res) => {
          if (res.data) {
            this.examInfo = res.data;
            // isOpenPCCamera 是否开启电脑摄像头(1:是;0:否)
            // isOpenPhoneCamera 是否开启手机摄像头(1:是;0:否)
            // showDebugButton 是否显示调试设备按钮(true:是;false:否)
            // beLate 是否迟到(true:是;false:否)
            if (this.examInfo.isOpenPCCamera) {
              this.isFaceRecogBeforeExam = 0;
              this.isFaceRecogDuringExam = 0;
              this.isFaceRecogCommitExam = 0;
            } else {
              this.isFaceRecogBeforeExam = this.examInfo.isFaceRecogBeforeExam;
              this.isFaceRecogDuringExam = this.examInfo.isFaceRecogDuringExam;
              this.isFaceRecogCommitExam = this.examInfo.isFaceRecogCommitExam;
            }
            sessionStorage.setItem('isFaceRecogBeforeExam',JSON.stringify(this.isFaceRecogBeforeExam));
            sessionStorage.setItem('isFaceRecogDuringExam',JSON.stringify(this.isFaceRecogDuringExam));
            sessionStorage.setItem('isFaceRecogCommitExam',JSON.stringify(this.isFaceRecogCommitExam));
          }
        });
    },
    // 获取考试历史记录
    findUserPaperListById() {
      this.$api.examination
        .findUserPaperListById(this.bussinessId, this.examId, this.type)
        .then((res) => {
          if (res.data) {
            // res.data.list.forEach((item) => {
            //   item.useDuration = this.timeFormatter(item.useDuration);
            // });
            this.historyList = res.data.list;
            if (this.historyList.length > 0) {
              this.noList = false;
            } else {
              this.noList = true;
            }
          }
        });
    },
    joinExam() {
      const params = {
        bussinessId:this.bussinessId,
        businessType:this.type,
        channel:this.examOrigin,
        examId:this.examId,
        userPaperId:this.examBasicsInfo.userPaperId
      }
      this.$api.examination
        .joinExam({params})
        .then((res) => {
          if (res.data && res.success) {
            this.examDialogVisible = false;
            this.$router.push({
                path: "/exam/testPaper",
                query: {
                  userPaperId: res.data.userPaperId,
                  businessId: this.bussinessId,
                  examId: this.examId,
                  taskType: this.type,
                  dymode: this.$route.query.dymode,
                  isMonitor:this.examBasicsInfo.isMonitor
                },
            });
            
            // 考试允许用时
            sessionStorage.setItem('secondsExam',JSON.stringify(res.data.seconds));
            // 允许交卷时长(单位:分;0:无,默认值0)
            sessionStorage.setItem('allowCommitDurationExam',JSON.stringify(res.data.allowCommitDuration));
            Vue.prototype.resetSetItem("signOutStorage", "login");
            sessionStorage.removeItem("remainingTime");
            sessionStorage.removeItem("questionsList");
            sessionStorage.removeItem("submissionTime");
          }
        });
    },
    // 进入考场
    async exampath() {
      
      if(this.examInfo.isLimitTime && this.$dateFormat.dateFormat() < this.examInfo.examStartDate){
        this.$message.warning(this.$t('WDNPC_WEB_THE_EXAM_TIME_HAS_NOT_ARRIVED',{text:'该考试时间未到'}))
      }else {
        console.log(this.SuccessFace,'this.SuccessFace')
        if (this.isFaceRecogBeforeExam == 1 && !this.SuccessFace) {
          console.log('人脸')
          this.verification = true;
          this.confirmdialog.show = true;
          if(this.examBasicsInfo&&this.examBasicsInfo.pop){
            this.verificationAddress = {
              path: "/examDetails",
              query: this.$route.query,
            };
          }else {
            this.verificationAddress = {
              path: "/exam/resdExam",
              query: {
                examId: this.examInfo.examId,
                businessId: this.bussinessId,
                dymode: this.$route.query.dymode,
                taskType: this.type, //taskType为1，则为从培训任务跳过来的
                // 入口
                source: 'examDetails'
              },
            };
          }
          
          this.verificationFailAddress = {};
        } else {
          if(this.examBasicsInfo&&this.examBasicsInfo.pop){
            this.examDialogVisible = true;
          }else{
            this.$router.push({
              path: "/exam/resdExam",
              query: {
                examId: this.examInfo.examId,
                businessId: this.bussinessId,
                dymode: this.$route.query.dymode,
                taskType: this.type,
                // 入口
                source: 'examDetails',
                name:this.examInfo.name,
                isMonitor:this.examBasicsInfo && this.examBasicsInfo.isMonitor 
              },
            });
          }
          
        }
      }
    },
    // 判断是否已实名
    async realName() {
       const params = {
          id:this.examId,
          businessId:this.bussinessId,
          businessType:this.type
      }
      const res = await this.$api.examination.findBasicExamById({params});
      if (res.data && res.success) {
        this.examBasicsInfo = res.data;
        sessionStorage.setItem('readingText',res.data.notes)
      }
      if(res.code!=0) return
      if (this.examInfo.beLate && !this.examBasicsInfo.pop) {
        this.dialogCon = this.$t('WDNPC_WEB_YOU_HAVE_EXCEEDED_THE_LATEST_OPENING_TIME',{text:'您已超过最迟开考时间！'});
        this.warningdialogVisible = true;
      } else if (this.examInfo.isOpenPCCamera) {
        this.judgefaceauth('device');
      } else {
        if (this.isFaceRecogBeforeExam == 0 && this.isFaceRecogCommitExam == 1 ||
        this.isFaceRecogBeforeExam == 0 && this.isFaceRecogDuringExam == 1 ) {
          this.judgefaceauth('goExam');
        } else {
          this.exampath();
        }
      }
    },
    // 是否实名
    judgefaceauth(type) {
      const goPath = type;
      this.$api.face.judgefaceauth({data:{}}).then((res)=>{
        var authToken = localStorage.getItem("token");
        var authTokenParam = authToken + "true";
        var authMd5Param = md5(authTokenParam).toLowerCase();
        var authResData = res.data && res.data.toLowerCase();
        if(authMd5Param !== authResData){
          this.realNameDialog = true;
          this.closeRealName = true;
        } else {
          // 类型为去考试--进入考场
          if (goPath == 'goExam') {
            this.exampath();
          } else {
              // 判断是否调整好了设备
              let params = {
                  businessId: this.$route.query.bussinessId,
                  examId:this.examInfo.examId,
                  businessType: this.$route.query.type
              }
              this.$api.exam.queryLinkStatusAndPhoneLive(params).then(res=>{
                  if(res.data.isOpenPhoneCamera==1){ // 开启了h5
                      if (res.data.pcLiveStatus&&res.data.phoneLiveStatus) {
                          this.exampath();
                      } else {
                          this.dialogCon = this.$t('WDNPC_WEB_IT_IS_DETECTED_THAT_YOUR_EQUIPMENT_HAS',{text:'检测到您设备未调整完善，为保证考试顺畅，请调整好设备后再进入考场'});
                          this.warningdialogVisible = true;
                      }
                  }else if(res.data.isOpenPhoneCamera==0){ // 没有开启h5
                      if (res.data.pcLiveStatus) {
                          this.exampath();
                      } else {
                          this.dialogCon = this.$t('WDNPC_WEB_IT_IS_DETECTED_THAT_YOUR_EQUIPMENT_HAS',{text:'检测到您设备未调整完善，为保证考试顺畅，请调整好设备后再进入考场'});
                          this.warningdialogVisible = true;
                      }
                  }


              })
          }
        }
      })
    },
    examtip() {
      if (this.examInfo.lockCourseRate && this.examInfo.lockExamCondition) {
        this.tipcon = this.$t('WDNPC_WEB_IF_THE_EXAM_IS_NOT_ENABLED_THE',{text:'该考试未开启，开启条件为前面的课程学习进度达到'}) + `${this.examInfo.lockCourseRate}%` + this.$t('WDNPC_WEB_PASS_THE_EXAM',{text:'，考试及格'});

      } else if (this.examInfo.lockCourseRate) {
        this.tipcon = this.$t('WDNPC_WEB_IF_THE_EXAM_IS_NOT_ENABLED_THE',{text:'该考试未开启，开启条件为前面的课程学习进度达到'}) + `${this.examInfo.lockCourseRate}%`;
      } else if (this.examInfo.lockExamCondition) {
        this.tipcon = this.$t('WDNPC_WEB_IF_THE_EXAM_IS_NOT_OPENED_THE',{text:'该考试未开启，开启条件为前面的考试及格'});
      }
      this.$message({
        showClose: true,
        message: this.tipcon,
        type: "warning",
      });
    },
    goHandPaper(item){
        this.$router.push({
          path: "/exam/handPaper",
          query: {
            userPaperId: item.id,
            businessId: this.bussinessId,
            examId: this.examInfo.examId,
            // 用来判断是否显示重考按钮
            type: "historyList",
            dymode: this.$route.query.dymode,
            //taskType为1，则为从培训任务跳过来的
            taskType: this.type,
            // 入口
            source: 'examDetails'
          },
        });
    },
    // 查看详情
    viewDetails(item) {
      console.log('查看详情')
      if(item.commitStatus === 2){
        this.$api.examination
        .findUserPaperSurface(item.id)
        .then((res) => {
            console.log(res.message,'message')
            if(res.code === 50001){
              this.$message({
                message: res.message,
                type: 'warning'
              });
            }else{
              this.goHandPaper(item)
            }
        }).catch(err => {
            console.log(err,'errr')
        })
        
      }else if (!item.isReadOver) {
        this.$message({
          message: this.$t('WDNPC_WEB_PLEASE_WAIT_PATIENTLY_DURING_MARKING',{text:'阅卷中，请耐心等待'}),
          type: 'warning'
        });
      } else if (this.examInfo.examId) {
          this.goHandPaper(item)
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.training {
  /deep/.el-collapse {
    border: none;
  }

  /deep/.el-collapse-item__arrow {
    display: none;
  }

  /deep/.el-collapse-item__header {
    background: rgba(49, 111, 255, 0.07);
    border: 1px solid #D7DCEE;
    padding: 0 12px;
    height: 58px;
    line-height: 58px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1B2257;

    img {
      margin-right: 4px;
    }
  }

  /deep/.el-collapse-item {
    margin-bottom: 16px;
  }

  /deep/.el-collapse-item__wrap {
    border: 1px solid #D7DCEE;
    border-top: none;
  }

  /deep/.el-collapse-item__content {
    padding: 12px 16px;
  }

  /deep/.el-collapse-item__header.is-active {
    border-bottom-color: #d7dcee;
  }
}
</style>
<style lang="stylus" scoped>
@import '../css/examDetails.styl';
.alertDialog {
  z-index: 99999 !important;
  /deep/.el-dialog {
    height: auto;
    width: 400px
    .el-dialog__body {
      padding: 0px !important;
      text-align: center;
      .tips-icon{
        font-size: 50px;  
        margin-top: 40px;
      }
      .text {
        margin-left: 0;
        margin-top: 18px;
        margin-bottom: 19px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
        padding: 0 50px;
        text-align: center;
      }
      .btn {
        margin-left: 0;
        width: 240px;
        height: 40px;
        background: #316fff;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;
        border: 0px;
        border-radius: 10px;
        margin-bottom: 32px;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        outline: none;
      }
    }
  }
  
}
</style>
<style lang="stylus">
.el-tooltip__popper.is-dark {
  .con {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
  }
}

.el-tooltip__popper .popper__arrow {
  top: 12px !important;
}

// 兼容火狐
@-moz-document url-prefix() {
  .training section {
    .no-course .history-list {
      overflow: hidden;

      .list-item {
        margin-right: -17px;
        margin-bottom: -17px;
      }
    }
  }
}
</style>
